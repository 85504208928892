import _ from 'lodash';

const generateErrorMessage = (error) => {
  let errorMessage = '';
  if (error.response && error.response.data) {
    let inputErrors;
    if (error.response.data.inputs) {
      inputErrors = _.values(error.response.data.inputs);
    }
    errorMessage = inputErrors
      ? inputErrors.join('\n')
      : error.response.data.message;
  } else {
    errorMessage = error.message;
  }

  return errorMessage;
};

export default generateErrorMessage;
