import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PublicRoute extends Component {
  async componentDidMount() {
    await this.props.checkUser();
  }

  render() {
    const {
      isAuthenticated,
      path,
      component,
      render,
      initialPage,
    } = this.props;
    const rootPath = `/${window.programConfig.program_id}`;

    return isAuthenticated ? (
      <Redirect
        to={
          initialPage ? `${rootPath}/${initialPage}` : `${rootPath}/dashboard`
        }
      />
    ) : (
      <Route
        path={path}
        component={component}
        render={render}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({
  authStore: { isAuthenticated },
  reportingStore: { navigationMenu, initialPage },
}) => {
  return {
    isAuthenticated,
    navigationMenu,
    initialPage,
  };
};

const mapDispatchToProps = ({ authStore: { checkUser } }) => {
  return {
    checkUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
