import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import cookieStorage from 'cookie-storage-v2';
import CookieStorage from './../utilities/cookieStorage.utility';

const TOKEN_KEY = {
  ACCESS_TOKEN: `web-dashboard-${process.env.REACT_APP_HOST}-access-token`,
  REFRESH_TOKEN: `web-dashboard-${process.env.REACT_APP_HOST}-refresh-token`,
  ID_TOKEN: `web-dashboard-${process.env.REACT_APP_HOST}-id-token`,
  REDIRECT_URI: `web-dashboard-${process.env.REACT_APP_HOST}-redirect-uri`,
  USER_ID: `web-dashboard-${process.env.REACT_APP_HOST}-user-id`,
  USER_TYPE: 'user-type',
};

class AuthService {
  UserManager;
  accessToken;

  constructor(AUTH_URL) {
    const realmId =
      window.programConfig.keycloak_connect_configuration['realm'];
    const programId = window.programConfig.program_id;

    this.UserManager = new UserManager({
      authority: `${window.programConfig.keycloak_connect_configuration['auth-server-url']}/realms/${realmId}`,
      client_id:
        window.programConfig.keycloak_connect_configuration['client_id'],
      redirect_uri: `${window.config.HOST}/${programId}/authentication/signin-callback`,
      silent_redirect_uri: `${window.config.HOST}/${programId}/authentication/silent-renew`,
      post_logout_redirect_uri: `${window.config.HOST}/${programId}/login`,
      response_type: 'code',
      automaticSilentRenew: false,
      userStore: new WebStorageStateStore({ store: new CookieStorage() }),
    });

    // Logger
    Log.logger = console;
    // Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      this.accessToken = user.access_token;
      cookieStorage.setItem(TOKEN_KEY.ACCESS_TOKEN, user.access_token);
      cookieStorage.setItem(TOKEN_KEY.ID_TOKEN, user.id_token);

      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });

      if (window.location.href.indexOf('signin-callback') !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = () => {
    return this.UserManager.getUser();
  };

  parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = (authResult) => {
    const data = this.parseJwt(this.accessToken);

    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    cookieStorage.setItem(
      TOKEN_KEY.REDIRECT_URI,
      window.location.pathname
    );
    return this.UserManager.signinRedirect({});
  };

  setUser = (data) => {
    cookieStorage.setItem(TOKEN_KEY.USER_ID, data.sub);

    // TODO: Should be done in the API
    // Set user-type based on given userIds mapped with emails
    if (
      [
        'd7f1d686-2f2f-458c-a571-dc0ba2edf9f1',
        'b962ce56-846d-43ee-b4fb-f08e812864c1',
      ].includes(data.sub)
    ) {
      cookieStorage.setItem(TOKEN_KEY.USER_TYPE, 'nvid-oncology-user');
    } else if (
      [
        '09af38b4-9bb5-4c63-a507-7781c01c790a',
        'f3e5359b-6dc7-450d-9936-91fe91a64bd8',
        '947369a1-e144-4f9d-8c59-6746a2501a1f',
        '05871432-bd65-4bff-8076-a5e6ef800714'
      ].includes(data.sub)
    ) {
      cookieStorage.setItem(TOKEN_KEY.USER_TYPE, 'nvid-uperio-user');
    }
  };

  navigateToScreen = () => {
    const redirectUri = cookieStorage.getItem(TOKEN_KEY.REDIRECT_URI)
      ? cookieStorage.getItem(TOKEN_KEY.REDIRECT_URI)
      : '/';

    window.location.replace(redirectUri);
  };

  setSessionInfo(authResult) {
    cookieStorage.setItem(TOKEN_KEY.ACCESS_TOKEN, authResult.accessToken);
    cookieStorage.setItem(TOKEN_KEY.ID_TOKEN, authResult.idToken);
  }

  getSessionInfo() {
    let result = null;

    const isAuthenticated = this.isAuthenticated();

    if (isAuthenticated) {
      const accessToken = cookieStorage.setItem(TOKEN_KEY.ACCESS_TOKEN);
      const idToken = cookieStorage.setItem(TOKEN_KEY.ID_TOKEN);

      result = {
        accessToken,
        idToken,
      };
    }

    return result;
  }

  isAuthenticated = () => {
    const access_token = cookieStorage.getItem(TOKEN_KEY.ACCESS_TOKEN);
    return !!access_token;
  };

  signinSilent = () => {
    return this.UserManager.signinSilent();
  };

  signinSilentCallback = () => {
    return this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect();
    cookieStorage.clear();
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      cookieStorage.clear();
      window.location.replace(process.env.REACT_APP_HOST_URL);
    });
    this.UserManager.clearStaleState();
  };
}

export default AuthService;
