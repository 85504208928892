import apiClient from '../utilities/apiClient.utility';
import _ from 'lodash';

const hkapiUrl = `${window.config.CONNECT_URLS.hkapi}`;
const storageUrl = `${window.config.CONNECT_URLS.storage}`;

export default {
  postAddProgram: async (payload) => {
    return await apiClient.post(`${hkapiUrl}/program-catalog`, payload);
  },

  putUpdateProgram: async (payload) => {
    const { id } = payload;
    return await apiClient.put(`${hkapiUrl}/program-catalog/${id}`, payload);
  },

  deleteProgram: async (payload) => {
    const { id } = payload;
    return await apiClient.del(`${hkapiUrl}/program-catalog/${id}`);
  },

  getSpecialtyList: async (payload) => {
    const { programConfig } = window;
    const userType = _.find(programConfig.users.user_types, {
      user_type: 'hkapi-user',
    });

    const specializationAreaField = _.find(userType.user_field_configurations, {
      name: 'specialization_area',
    });

    const specialtyList = _.orderBy(
      specializationAreaField.field_type_options.select_dropdown_options,
      ['label'],
      ['asc']
    );

    return specialtyList;
  },

  postUploadMap: async (payload) => {
    const { file } = payload;
    const formData = new FormData();
    formData.append('file', file);
    return await apiClient.post(
      `${storageUrl}/${window.programConfig.program_id}/upload`,
      formData
    );
  },
};
