import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '../config';

import PublicRoute from '../containers/RouterHOC/PublicRoute';
import ProtectedRoute from '../containers/RouterHOC/ProtectedRoute';

import LogoutPage from '../components/authentication/Logout';
import LogoutCallback from '../components/authentication/LogoutCallback';
import SignInCallback from '../components/authentication/SignInCallback';
import SilentRenew from '../components/authentication/SilentRenew';

import PopupAlert from '../components/PopupAlert/PopupAlert.component';
/*import ErrorPage from '../components/Error/Error.component';*/
import LoginPage from '../containers/LoginPage/LoginPage.container';
import PageContainer from '../containers/PageContainer/Page.container';

const AppRouter = () => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <PopupAlert />
      <BrowserRouter>
        <Switch>
          <PublicRoute
            path="/:connect_program_id/login"
            component={LoginPage}
            exact
          />
          <ProtectedRoute
            path="/:connect_program_id/authentication/logout"
            component={LogoutPage}
            exact
          />
          <ProtectedRoute
            path="/:connect_program_id/authentication/logout-callback"
            component={LogoutCallback}
            exact
          />
          <PublicRoute
            path="/:connect_program_id/authentication/signin-callback"
            component={SignInCallback}
            exact
          />
          <Route
            path="/:connect_program_id/authentication/silent-renew"
            component={SilentRenew}
            exact
          />
          <ProtectedRoute
            path="/:connect_program_id/:page_key"
            component={PageContainer}
            exact
          />
          <Route
            path="/:connect_program_id"
            render={() => (
              <Redirect to={`/${window.programConfig.program_id}/login`} />
            )}
            exact
          />

          {/*<Route component={ErrorPage} />*/}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
