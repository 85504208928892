import hkapiService from '../services/hkapi.service';

export default {
  state: {
    programDetail: {
      brand_name: null,
      generic_name: null,
      ngo: null,
      ngo_contact_details: null,
      inclusion_criteria: null,
      method_of_application: null,
      specialty: [],
      require_receipt: null,
      contact_information: [],
      support: [],
      version_number: null,
    },
    specialtyList: [],
  },
  reducers: {
    setProgramDetail(state, payload): any {
      return {
        ...state,
        programDetail: payload,
      };
    },
    setSpecialtyList(state, payload): any {
      return {
        ...state,
        specialtyList: payload,
      };
    },
  },
  effects: (dispatch): any => ({
    postAddProgram: async (payload): Promise<any> => {
      try {
        const { result } = await hkapiService.postAddProgram(payload);

        dispatch.hkapiStore.setProgramDetail(result);
      } catch (e) {
        console.error(e);
      }
    },

    putUpdateProgram: async (payload): Promise<any> => {
      try {
        const result = await hkapiService.putUpdateProgram(payload);

        dispatch.hkapiStore.setProgramDetail(result);
      } catch (e) {
        console.error(e);
      }
    },

    deleteProgram: async (payload): Promise<any> => {
      try {
        const result = await hkapiService.deleteProgram(payload);

        dispatch.hkapiStore.setProgramDetail(result);
      } catch (e) {
        console.error(e);
      }
    },

    getSpecialtyList: async (payload): Promise<any> => {
      try {
        const result = await hkapiService.getSpecialtyList(payload);

        dispatch.hkapiStore.setSpecialtyList(result);
      } catch (e) {
        console.error(e);
      }
    },

    postUploadMap: async (payload): Promise<any> => {
      try {
        const { result } = await hkapiService.postUploadMap(payload);
        return result?.url;
      } catch (e) {
        console.error(e);
      }
    },
  }),
};
