import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { updateUserChasCard } from '../../services/user.service';

const ChasCardApproval = ({ data }) => {
  const ACTION_APPROVE = 'approve';
  const ACTION_REJECT = 'reject';
  const { program_id, patient_id, chasis_card_status, user_tier } = data;
  const [openConfirmation, setConfirmation] = useState(false);
  const [confirmApproveDisapprove, setConfirmApproveDisapprove] = useState(false);
  const [successMessage, setSuccessMessage] = useState('Successfully updated!');
  const [isLoading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  
  const handleClickAction = (status) => {
      setAction(status);
      setConfirmApproveDisapprove(true);
  }

  const handleClose = () => {
    setConfirmation(false);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const onSubmitApproveDisapprove = async () => {
    try {
      setConfirmApproveDisapprove(false);
      if (!user_tier && action === ACTION_APPROVE) {
        alert("Cannot approve CHAS Card. User Tier must be assigned first.");
        return;
      }
      setLoading(true);
      await updateUserChasCard({
        program_id: program_id,
        id: patient_id,
        action: action,
      });
      let actionMessage = 'CHAS Card status has been updated'
      if (action === ACTION_REJECT) {
        actionMessage = 'CHAS Card has been rejected';
      }
      if (action === ACTION_APPROVE) {
        actionMessage = 'CHAS Card has been approved';
      }
      setSuccessMessage(actionMessage);
      setConfirmation(true);
    } catch(err) {
      setLoading(false);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  }
 
  const formButtons = (
    <>
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
    </>
  );

  const confirmApproveButtons = (
    <>
        <Button onClick={() => setConfirmApproveDisapprove(false)} variant='contained' color='secondary'>
          Close
        </Button>
        <Button onClick={onSubmitApproveDisapprove} variant='contained' color='primary'>
          Yes
        </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Dialog 
      key='confirm-approve-disapprove'
      open={confirmApproveDisapprove}
      title = 'Confirmation'
      content = {`Are you sure to ${action} the CHAS Card?`}
      children = {confirmApproveButtons}
      onClose={() => setConfirmApproveDisapprove(false)}
      />
      {
        chasis_card_status == "pending" ?
        <div>
          <Button variant="contained" color="primary" onClick={() => handleClickAction(ACTION_APPROVE)} style={{ marginRight: 8, width: "0", fontSize: "8px" }} >
            Approve
          </Button>
          <Button variant="contained" color="error"  onClick={() => handleClickAction(ACTION_REJECT)} 
          style={{ marginRight: 8, width: "0", fontSize: "8px", background: "#E53935", color: "#fff" }}>
            Reject
          </Button>
        </div> : '--'
      }
    </>
  )
};

export default ChasCardApproval;