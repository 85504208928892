import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import mustache from 'mustache';
import { Markup } from 'interweave';

import { DataCard } from '../Card/Card.component';
import stores from '../../models/index.model';

const styles = (theme) => ({
  header: {
    '& th': {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
  },
  emptyText: {
    textAlign: 'center',
    padding: '90px 0',
    color: '#999999',
    width: '100%',
  },
  solidHeader: {
    backgroundColor: theme.palette.primary.main,
    '& th': {
      color: '#ffffff',
      padding: '10px 15px',
    },
  },
  actionButton: {
    minWidth: 20,
  },
  linkCell: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
});

const replaceText = (text, params) => {
  return mustache.render(text, params);
};

const TableComponent = withStyles(styles)(
  ({
    columns = [],
    data = [],
    match,
    onEdit,
    onDelete,
    emptyText,
    classes,
    solidHeader = false,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onChangePage,
    onChangeRowsPerPage,
  }) => (
    <Grid container justify="flex-end" alignItems="flex-start">
      <TableContainer>
        <Table>
          <TableHead
            className={solidHeader ? classes.solidHeader : classes.header}
          >
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.Header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((datum, index1) => (
                <TableRow key={index1}>
                  {columns.map((column, index2) => {
                    switch (column.col_type) {
                      case 'date':
                        const date = datum[column.accessor];
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {date
                              ? moment(date).format('MM/DD/YYYY HH:mm')
                              : '--'}
                          </TableCell>
                        );
                      case 'link':
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            <Link
                              className={classes.linkCell}
                              to={
                                '/' +
                                match.params.connect_program_id +
                                replaceText(column.link, datum)
                              }
                            >
                              {column.label || datum[column.accessor]}
                            </Link>
                          </TableCell>
                        );
                      case 'array':
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {datum[column.accessor].join(', ')}
                          </TableCell>
                        );
                      case 'hkapi-contact':
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {datum[column.accessor][0]?.value}
                          </TableCell>
                        );
                      case 'image':
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {datum[column.accessor] && (
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  stores.dispatch.uiStore.showPopupModal({
                                    title: column.Header,
                                    message: (
                                      <img
                                        style={{
                                          width: '100%',
                                          margin: '0 auto',
                                          display: 'block',
                                        }}
                                        src={datum[column.accessor]}
                                      />
                                    ),
                                    actions: [
                                      {
                                        label: 'Full View',
                                        onClick: () => {
                                          const link = document.createElement(
                                            'a'
                                          );
                                          link.href = datum[column.accessor];
                                          link.target = '_blank';
                                          document.body.appendChild(link);
                                          link.click();
                                          document.body.removeChild(link);
                                        },
                                      },
                                    ],
                                  })
                                }
                              >
                                View Image
                              </Button>
                            )}
                          </TableCell>
                        );
                      case 'edit-delete':
                        return (
                          <TableCell
                            key={index2}
                            align="right"
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            <Button
                              size="small"
                              onClick={() => onEdit(index1)}
                              className={classes.actionButton}
                            >
                              <Edit />
                            </Button>
                            <Button
                              size="small"
                              onClick={() => onDelete(index1)}
                              className={classes.actionButton}
                            >
                              <Delete />
                            </Button>
                          </TableCell>
                        );
                      case 'html':
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            <Markup content={datum[column.accessor]} />
                          </TableCell>
                        );
                      case 'published':
                        const publishedStatus =
                          datum[column.accessor] === 1 ? 'Published' : 'Draft';
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {publishedStatus}
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              width: column.width ? column.width : 'auto',
                            }}
                          >
                            {datum[column.accessor]}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length < 1 && emptyText && (
        <div className={classes.emptyText}>
          <Typography variant="h6">{emptyText}</Typography>
        </div>
      )}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Grid>
  )
);

class MUITable extends React.Component {
  state = {
    searchResult: null,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 100],
  };

  componentDidMount() {
    this.setState({
      rowsPerPage: this.props.rowsPerPage || 10,
      rowsPerPageOptions: this.props.rowsPerPageOptions || [10, 25, 100],
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    const { data, searchKeys } = this.props;
    const result = [];

    data.forEach((obj) => {
      searchKeys.some((key) => {
        const keyValue = JSON.stringify(obj[key]).toLowerCase();
        const searchValue = value.toLowerCase();

        if (keyValue.includes(searchValue)) {
          result.push(obj);
          return true;
        }
      });
    });

    data.forEach((obj) => {
      searchKeys.some((key) => {
        const exists = result.some(
          (currentObj) => JSON.stringify(currentObj) === JSON.stringify(obj)
        );

        if (!exists) {
          const keyValue = JSON.stringify(obj[key])
            .toLowerCase()
            .replace(/[.,\\\/#!$%^&*;:{}=\-_`'~()|]/g, '');
          const searchValue = value
            .toLowerCase()
            .replace(/[.,\\\/#!$%^&*;:{}=\-_`'~()|]/g, '');

          if (keyValue.includes(searchValue)) {
            result.push(obj);
            return true;
          }
        }
      });
    });
    this.setState({ searchResult: result });
  };

  render() {
    const {
      title,
      actions,
      data = [],
      match,
      history,
      searchKeys,
    } = this.props;
    const { searchResult, page, rowsPerPage, rowsPerPageOptions } = this.state;

    return title ? (
      <DataCard
        title={title}
        actions={actions}
        dataLength={data.length}
        history={history}
        match={match}
        onSearch={
          searchKeys
            ? {
                placeholder: 'Search',
                onInputChange: this.handleSearch,
              }
            : null
        }
      >
        <TableComponent
          {...this.props}
          data={searchResult || data}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </DataCard>
    ) : (
      <TableComponent
        {...this.props}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }
}

export default MUITable;
