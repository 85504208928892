import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Divider,
  Drawer,
  Hidden,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import * as Icons from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { some } from 'lodash';

import ListItemLink from '../../components/ListItemLink';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const hasChildren = (navItem) => {
  return navItem.children && navItem.children.length;
};

const Menu = ({
  navItem,
  selected = false,
  /*
    I had to start with 1 to accomodate the spacing from the left side.
    If we start with 0, the ones in the 1st set of children are going to start at the same level as the parent.
  */
  treeLevel = 1,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const params = queryString.parse(history.location.search);
  const stringParams = `?${queryString.stringify({
    start_date: params.start_date,
    end_date: params.end_date,
  })}`;
  const currentPaths = window.location.pathname;

  const NavIcon = Icons[navItem.icon_key];

  return (
    <React.Fragment>
      <ListItem
        button
        selected={selected}
        style={{
          paddingLeft: theme.spacing(treeLevel) * 2,
        }}
        ref={anchorRef}
        aria-controls={open ? `${navItem.icon_key}-menu` : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ListItemIcon>{NavIcon && <NavIcon />}</ListItemIcon>

        <ListItemText primary={navItem.label} style={{ textAlign: 'left' }} />

        <ListItemIcon
          style={{
            justifyContent: 'flex-end',
            minWidth: '30px',
          }}
        >
          <Icons.ChevronRight />
        </ListItemIcon>
      </ListItem>

      <MuiMenu
        id={`${navItem.icon_key}-menu`}
        open={open}
        keepMounted
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onKeyDown={handleListKeyDown}
        onClose={handleClose}
      >
        {hasChildren(navItem) &&
          navItem?.children?.map((menuItem) => {
            return (
              <ListItemLink
                key={menuItem.key}
                to={`/${window.programConfig.program_id}/${menuItem.key}${stringParams}`}
                exact={true}
              >
                <MuiMenuItem
                  selected={currentPaths.includes(menuItem.key)}
                  onClick={handleClose}
                >
                  {menuItem.label}
                </MuiMenuItem>
              </ListItemLink>
            );
          })}
      </MuiMenu>
    </React.Fragment>
  );
};

/*


*/
const ListItems = ({
  navigationMenu,

  /*
    I had to start with 1 to accomodate the spacing from the left side.
    If we start with 0, the ones in the 1st set of children are going to start at the same level as the parent.
  */
  treeLevel = 1,
  parentItem,
}): JSX.Element => {
  const theme = useTheme();
  const paddingLeft = treeLevel !== 1 && theme.spacing(treeLevel) * 2;
  const currentPaths = window.location.pathname;
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const stringParams = `?${queryString.stringify({
    start_date: params.start_date,
    end_date: params.end_date,
  })}`;

  return (
    <React.Fragment>
      {navigationMenu?.map((navItem, index) => {
        const NavIcon = Icons[navItem.icon_key];
        return (
          <React.Fragment key={navItem.key}>
            {navItem.type === 'page' && (
              <React.Fragment>
                <ListItemLink
                  to={`/${window.programConfig.program_id}/${navItem.key}${stringParams}`}
                  exact={'true'}
                >
                  <ListItem
                    button
                    selected={
                      currentPaths.includes('pfsg') || currentPaths.includes('mycare')
                        ? currentPaths.split('/')[2] === navItem.key
                        : currentPaths.includes(navItem.key)
                    }
                    style={{
                      paddingLeft,
                    }}
                  >
                    <ListItemIcon>{NavIcon && <NavIcon />}</ListItemIcon>

                    <ListItemText primary={navItem.label} />
                  </ListItem>
                </ListItemLink>

                {hasChildren(navItem) && (
                  <ListItems
                    parentItem={navItem}
                    navigationMenu={navItem?.children}
                    treeLevel={treeLevel + 1} 
                  />
                )}
              </React.Fragment>
            )}

            {navItem.type === 'menu' && (
              <div>
                {hasChildren(navItem) && (
                  <Menu
                    navItem={navItem}
                    treeLevel={treeLevel}
                    selected={
                        currentPaths.includes('mycare') ?
                           some(navItem.children, { key: currentPaths.split('/')[2] })
                          : currentPaths.includes(navItem.key)
                      }
                  />
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const NavigationMenu = ({ data }) => {
  const classes = useStyles();

  return (
    <div>
      <Hidden xsDown implementation="css">
        <div className={classes.toolbar} />
        <Divider />
      </Hidden>
      <List>
        <ListItems parentItem="" navigationMenu={data} />
      </List>
    </div>
  );
};

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [sidebarData, setSidebarData] = useState(null);
  useEffect(() => {
    setSidebarData(props.navigationMenu);
  }, [props.navigationMenu]);

  const ListItemSkeleton = () => {
    return (
      <div style={{ padding: 8 }}>
        {[...Array(6)].map((v, i) => {
          return (
            <Skeleton key={`${i}`} width="100%" animation="wave" height={40} />
          );
        })}
      </div>
    );
  };

  if (props.isLoading || !sidebarData) {
    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={props.mobileOpen}
            onClose={props.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <ListItemSkeleton />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            container={container}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open
          >
            <div className={classes.toolbar} />
            <ListItemSkeleton />
          </Drawer>
        </Hidden>
      </nav>
    );
  }

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavigationMenu data={props.navigationMenu} />
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          open
        >
          <NavigationMenu data={props.navigationMenu} />
        </Drawer>
      </Hidden>
    </nav>
  );
}

const mapStateToProps = ({
  reportingStore: { config, navigationMenu },
  loading,
}) => {
  return {
    config,
    navigationMenu,
    isLoading: loading.models.reportingStore,
  };
};

const ConnectedResponsiveDrawer = connect(mapStateToProps)(ResponsiveDrawer);

export default ConnectedResponsiveDrawer;
