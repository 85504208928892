import moment from 'moment-timezone';
import apiClient from '../utilities/apiClient.utility';

const usersUrl = `${window.config.CONNECT_URLS.users}`;

const hkapiUrl = `${window.config.CONNECT_URLS.hkapi}`;

export const postAddProgram = async (payload) => {
  return await apiClient.post(`${hkapiUrl}/program-catalog`, payload);
};

export const downloadHKAPIReportExport = async (payload) => {
  const {
    end_date = moment().toJSON(),
    page_key,
    type,
    drug_manufacturer,
    label,
  } = payload;

  return apiClient.download(
    `${hkapiUrl}/${window.programConfig.program_id}/generate-report`,
    {
      end_date: end_date,
      page_key,
      type,
      drug_manufacturer,
    },
    `HKAPI-${label}-as-of-${moment(payload.end_date).format('YYYY-MM-DD')}.xlsx`
  );
};

export const postBpDelivery = async ({
  user_id,
  connect_program_id,
  isDelivered,
}) => {
  return await apiClient.post(
    `${usersUrl}/${connect_program_id}/profile/${user_id}/deliver-bp`,
    { is_delivered: isDelivered }
  );
};

export const approveOptOut = async ({ user_id }) => {
  return await apiClient.post(
    `${usersUrl}/${window.programConfig.program_id}/private/approve-opt-out`,
    { user_id }
  );
};

export const blacklistPatient = async ({ user_id }) => {
  return await apiClient.post(
    `${usersUrl}/${window.programConfig.program_id}/private/blacklist-patient/${user_id}`,
    null
  );
};