import jwtDecode from 'jwt-decode';
import stores from '../models/index.model';

export default {
  state: {
    authenticatedUser: null,
    isAuthenticated: null,
    userRoles: [],
  },
  reducers: {
    setAuthenticatedUser(state, payload) {
      return {
        ...state,
        authenticatedUser: payload,
        isAuthenticated: !!payload,
      };
    },
    setUserRoles(state, payload) {
      return {
        ...state,
        userRoles: payload,
      };
    },
  },
  effects: (dispatch) => ({
    checkUser: async () => {
      const user = await window.authService.getUser();
      window.apiClient.setLoggedInUser(user, window.authService.signinSilent);

      // POPULATE navigation menu
      if (user) {
        const userDetail = jwtDecode(user.access_token);
        const userRoles = userDetail.realm_access?.roles || [];
        const navigationMenu = [];
        if (userRoles.length <= 2) {
          stores.dispatch.uiStore.showPopupModal({
            title: window.programConfig.program_name,
            message: 'User does not exist. Please make sure to log-in with the correct credentials.',
            nonTransparentOverlay: true,
            disableCloseButton: true,
            actions: [
              {
                label: 'Confirm',
                onClick: () => dispatch.authStore.logout(),
              },
            ],
          });
        }
        dispatch.authStore.setUserRoles(userRoles);
        window.programConfig.reporting?.navigation_menu.forEach(
          (nav, index) => {
            if (
              nav.roles &&
              userRoles.some((role) => nav.roles.includes(role)) &&
              !nav.hidden
            ) {
              navigationMenu.push(nav);
              if (navigationMenu.length < 2) {
                dispatch.reportingStore.setInitialPage(nav.key);
              }
            }
            if (nav.initial_page) {
              dispatch.reportingStore.setInitialPage(nav.key);
            }
          }
        );
        dispatch.reportingStore.setNavigationMenu(navigationMenu);
      }

      return dispatch.authStore.setAuthenticatedUser(user);
    },

    signinRedirectCallback: () => {
      return window.authService.signinRedirectCallback();
    },

    logout: () => {
      dispatch.authStore.setAuthenticatedUser(null);
      return window.authService.logout();
    },

    signoutRedirectCallback: () => {
      return window.authService.signoutRedirectCallback();
    },

    isAuthenticated: () => {
      const isAuthenticated = window.authService.isAuthenticated();
      console.log(isAuthenticated);
      return isAuthenticated;
    },

    signinRedirect: () => {
      return window.authService.signinRedirect();
    },

    signinSilentCallback: () => {
      return window.authService.signinSilentCallback();
    },

    createSigninRequest: () => {
      return window.authService.createSigninRequest();
    },
  }),
};
