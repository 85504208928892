import React, { Component } from 'react';

import { connect } from 'react-redux';

import LoadingLogo from '../../LoadingLogo/LoadingLogo.component';
// // import { rematchContext } from '../../../models/index.model';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <LoadingLogo />;
  }
}

const mapDispatchToProps = ({ authStore: { logout } }) => {
  return {
    logout,
  };
};

const ConnectedLogout = connect(
  null,
  mapDispatchToProps,
  null
  // // { context: rematchContext }
)(Logout);

export default ConnectedLogout;
