import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import mustache from 'mustache';
import moment from 'moment-timezone';

// Components
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  withStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Container,
} from '@material-ui/core';
import { PersonTwoTone as PatientIcon } from '@material-ui/icons';
import * as Icons from '@material-ui/icons';
import ReactMarkdown from 'react-markdown';

import { SummaryCard } from '../../components/ui/Card';
import AggregatedValue from '../../components/AggregatedValue/AggregatedValue.component';
import PaginatedTable from '../PaginatedTable/PaginatedTable.component';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { LineGraph, ColumnChart } from '../Chart/Chart.component';
import MUITable from '../MUITable/MUITable.component';
import ProgramDetail from '../HKAPI/ProgramDetail.component';
import ResetPassword from '../ResetPassword/ResetPassword.component';

import actionButtons from '../../components/ActionButtons/index.actions.js';
import {
  approveOptOut,
  blacklistPatient,
  postBpDelivery,
} from '../../services/actions.service';

const blockStyles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
});

// type MyProps = { filterStore: any; summaryStore: any; getSummary: any };
// type MyState = { value: string };
class BlockDetail extends React.Component /*<MyProps, MyState>*/ {
  constructor(props) {
    super(props);

    this._handleChange = this._handleChange.bind(this);

    this.state = {
      data: null,
      isDataLoaded: false,
      imageBtnVisiblity: false,
    };
  }

  async componentDidMount() {
    await this._loadData();
  }

  async _handleChange(data, type) {
    // NOTE: Move the link/connection to the action or services to higher level components, ideally in route container/components and reducer actions.
    try {
      let connect_program_id = this.props.match.params.connect_program_id;

      switch (type) {
        case 'blacklist':
          await blacklistPatient({ user_id: data.user_id });
          break;
        case 'bpDeliveryDelivered':
          await postBpDelivery({
            user_id: data.user_id,
            isDelivered: true,
            connect_program_id,
          });
          break;

        case 'bpDeliveryNotDelivered':
          await postBpDelivery({
            user_id: data.user_id,
            isDelivered: false,
            connect_program_id,
          });
          break;

        case 'optOut':
          await approveOptOut({ user_id: data.user_id });
          break;

        default:
          break;
      }
      await this._loadData();
    } catch (e) {
      console.error(e.message);
      alert(e.message);
    }
  }

  _loadData = async () => {
    const { blockConfig, match, location } = this.props;
    console.log(`Looading block ${blockConfig.page_key}-${blockConfig.id}`);
    const programId = match.params.connect_program_id;

    const query = queryString.parse(location.search);
    const blockDetailResult = await this.props.getBlockDetail({
      connect_program_id: programId,
      page_key: blockConfig.page_key,
      block_id: blockConfig.id,
      query,
    });

    // SHOW filter note if exists
    this.props.showFilterNote(blockConfig.block_options?.filter_note || null);
    // APPLY filter date max range in months
    this.props.applyFilterDateMaxRangeinMonths(
      blockConfig.block_options?.filter_date_month_range_limit || null
    );
    // HIDE filter sidebar
    this.props.hideFilterSidebar(
      blockConfig.block_options?.hide_filter_sidebar || false
    );

    let btnVisiblity = false;

    this.setState({
      data: blockDetailResult,
      isDataLoaded: true,
      imageBtnVisiblity: btnVisiblity,
    });

    try {
      let response = await fetch('https://api.ipify.org?format=json');
      let result = await response.json();
      console.log('IP', result.ip);
      console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    } catch(e) {
      console.log('Error on IP retrieval')
    }
  };

  _renderPaginatedTable = (blockConfig, data, btnVisiblity) => {
    return (
      <PaginatedTable
        exportButton={blockConfig.block_options.export}
        addHCPButton={blockConfig.block_options.add_hcp}
        addPatientButton={blockConfig.block_options.add_patient}
        title={blockConfig.block_options.title}
        columns={blockConfig.block_options.columns}
        data={data}
        history={this.props.history}
        match={this.props.match}
        onChange={this._handleChange}
        btnVisiblity={btnVisiblity} 
        actions={blockConfig.block_options.actions}
      />
    );
  };

  _replaceText(text, params) {
    return mustache.render(text, params);
  }

  _formatValue(colType = null, value) {
    switch (colType) {
      // DATE column type
      case 'date':
        return value ? moment(value).format('MM/DD/YYYY HH:mm') : '--';
      // USER_ID column type
      case 'user_id':
        return value.slice(0, 5);
      default:
        return value;
    }
  }

  _renderSummaryCard = (blockConfig) => {
    return (
      <SummaryCard
        icon={<PatientIcon fontSize="large" style={{ marginRight: 6 }} />}
        title={blockConfig.block_options.title}
      >
        {blockConfig.block_options.columns.map((col, idx) => (
          <AggregatedValue
            key={idx}
            value={this._formatValue(
              col.col_type,
              this.state.data[col.accessor]
            )}
            description={col.Header}
            md={6}
            {...(col.link
              ? {
                  link: `/${
                    this.props.match.params.connect_program_id
                  }${this._replaceText(col.link, this.state.data)}`,
                }
              : {})}
          />
        ))}

        {blockConfig.block_options.actions.map((action, idx) => {
          if (!actionButtons[action]) {
            return <div key={action}>{`${action} not found`}</div>;
          }

          const ActionButtonComponent = actionButtons[action];
          return (
            <ActionButtonComponent
              key={action}
              data={this.state.data}
              {...this.props}
            />
          );
        })}
      </SummaryCard>
    );
  };

  _renderReportsExportCard = (blockConfig) => {
    const query = queryString.parse(this.props.location.search);
    const {
      block_options: blockOptions,
      page_key: pageKey,
      block_type,
    } = blockConfig;
    const ActionButtonComponent = blockOptions.action
      ? actionButtons[blockOptions.action]
      : null;
    const allowedReports = blockOptions.reports?.map((item) => item.label);

    return (
      <SummaryCard
        icon={<PatientIcon fontSize="large" style={{ marginRight: 6 }} />}
        title={blockOptions.title}
      >
        {this.state.data.map((row, idx) => {
          return (
            allowedReports.includes(row.label) && (
              <Grid item md={6} key={idx}>
                <Table>
                  <TableBody>
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" gutterBottom>
                          {row.label}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: '30%' }}>
                        {ActionButtonComponent ? (
                          <ActionButtonComponent
                            key={row.label}
                            label={row.label}
                            pageKey={pageKey}
                            query={query}
                            customQueries={row.custom_queries}
                            toggleLoader={(e) =>
                              this.setState({
                                isDataLoaded: !this.state.isDataLoaded,
                              })
                            }
                          />
                        ) : blockOptions.report_file ? (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={async () => {
                              this.setState({ isDataLoaded: false });
                              await this.props.downloadTemplatedReportExport({
                                label: row.label,
                                page_key: pageKey,
                                block_type,
                                report_file: blockOptions.report_file,
                                report_index: idx,
                                query,
                              });
                              this.setState({ isDataLoaded: true });
                            }}
                          >
                            Export
                          </Button>
                        ) : (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={async () => {
                              this.setState({ isDataLoaded: false });
                              await this.props.downloadReportExport({
                                page_key: pageKey,
                                label: row.label,
                                aggregation_page_key: row.aggregation_page_key,
                                query_index: row.query_index,
                                aggregation: row.aggregation,
                                query,
                              });
                              this.setState({ isDataLoaded: true });
                            }}
                          >
                            Export
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )
          );
        })}
      </SummaryCard>
    );
  };

  _renderLineChart = (blockConfig) => {
    return (
      <SummaryCard
        icon={<PatientIcon fontSize="large" style={{ marginRight: 6 }} />}
        title={blockConfig.block_options.title}
      >
        <LineGraph
          series={this.state.data}
          yAxis={blockConfig.block_options.y_axis}
          xAxis={blockConfig.block_options.x_axis}
        />
      </SummaryCard>
    );
  };

  /**
   * Render column chart based on data.
   *
   * @param {Object} blockConfig field accessor configuration.
   * @param {Object} data data for series.
   *
   * @return {DOM}
   */
  _renderColumnChart = (blockConfig, data) => {
    return (
      <SummaryCard
        icon={<PatientIcon fontSize="large" style={{ marginRight: 6 }} />}
        title={blockConfig.block_options.title}
      >
        <ColumnChart
          series={data}
          yAxis={blockConfig.block_options.y_axis}
          xAxis={blockConfig.block_options.x_axis}
        />
      </SummaryCard>
    );
  };

  _renderAggregateCard = (blockConfig) => {
    const { classes } = this.props;
    const CardIcon = Icons[blockConfig.block_options.icon_key];

    return (
      <SummaryCard
        icon={
          CardIcon && <CardIcon fontSize="large" style={{ marginRight: 6 }} />
        }
        title={blockConfig.block_options.title}
      >
        {blockConfig.block_options.columns.map((col, idx) => {
          const ColumnIcon = Icons[col.icon_key];

          return (
            <Grid item xs={6}>
              <ListItem>
                <ListItemAvatar>
                  {ColumnIcon && (
                    <Avatar className={classes.avatar}>
                      <ColumnIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={this.state.data[col.query_index][col.accessor]}
                  secondary={col.Header}
                />
              </ListItem>
            </Grid>
          );
        })}
      </SummaryCard>
    );
  };

  _renderMUITable = (blockConfig, data) => {
    return (
      <MUITable
        title={blockConfig.block_options.title}
        actions={blockConfig.block_options.actions}
        columns={blockConfig.block_options.columns}
        data={data}
        searchKeys={blockConfig.block_options.search_keys}
        {...this.props}
      />
    );
  };

  _renderHKAPIProgramDetail = (blockConfig, data) => {
    return (
      <ProgramDetail
        data={data && data.length > 0 ? data[0] : null}
        breadcrumbs={blockConfig.block_options.breadcrumbs}
        ngoList={blockConfig.block_options.ngo_list}
        {...this.props}
      />
    );
  };

  _renderResetPassword = (blockConfig, data) => {
    return (
      <ResetPassword
        title={blockConfig.block_options.title}
        label={blockConfig.block_options.label}
        history={this.props.history}
      />
    );
  };

  /**
   * Render markdown.
   *
   * @param {Object} blockConfig field accessor configuration.
   *
   * @return {DOM}
   */
  _renderMarkdown = (blockConfig) => {
    return (
      <Container>
        {blockConfig.block_options.p.map((item, index) => (
          <Typography>
            <ReactMarkdown key={`mdp_${index}`}>{item}</ReactMarkdown>
          </Typography>
        ))}
      </Container>
    );
  };

  render() {
    if (!this.state.isDataLoaded) {
      return <LoadingOverlay />;
    }

    const { blockConfig } = this.props;
    const { data } = this.state;
    const btnVisiblity = this.state.imageBtnVisiblity;

    switch (blockConfig.block_type) {
      case 'table':
        return this._renderPaginatedTable(blockConfig, data, btnVisiblity);
      case 'summary':
        return this._renderSummaryCard(blockConfig, data);
      case 'export':
        return this._renderReportsExportCard(blockConfig, data);
      case 'line-chart':
        return this._renderLineChart(blockConfig, data);
      case 'column-chart':
        return this._renderColumnChart(blockConfig, data);
      case 'aggregate':
        return this._renderAggregateCard(blockConfig, data);
      case 'hkapi-program-catalog':
        return this._renderMUITable(blockConfig, data);
      case 'hkapi-program-detail':
        return this._renderHKAPIProgramDetail(blockConfig, data);
      case 'markdown':
        return this._renderMarkdown(blockConfig);
      case 'reset-password':
        return this._renderResetPassword(blockConfig, data);
      default:
        console.error(blockConfig);
        return (
          <SummaryCard
            subtitle={`Invalid block type '${blockConfig.block_type}' not currently supported`}
          />
        );
    }
  }
}

const mapDispatchToPropsRematch = ({ reportingStore, filterStore }) => {
  return {
    ...reportingStore,
    ...filterStore,
  };
};

export default connect(
  null,
  mapDispatchToPropsRematch
)(withStyles(blockStyles)(BlockDetail));

BlockDetail.propTypes = {
  history: PropTypes.object,
  blockConfig: PropTypes.object,
  getBlockDetail: PropTypes.func,
};
