import moment from 'moment-timezone';
import reportingService from '../services/reporting.service';

export default {
  state: {
    config: null,
    publicConfig: {
      program_id: 'mycare',
      openid_configuration: {
        login_url: 'https://keycloak.dev/realms/mycare/token',
      },
      page_theme: {
        primary_color: '#fff',
        secondary_color: '#fff',
        login_page: {
          background_color: '#fff',
        },
      },
    },
    navigationMenu: [],
    initialPage: null,
    error: null,
    // publicConfig: null,
    filterNote: null,
    filterDateMaxRangeinMonths: null,
  },
  reducers: {
    setPublicConfig(state, payload): any {
      return {
        ...state,
        publicConfig: {
          ...payload,
        },
      };
    },

    setPrivateConfig(state, payload): any {
      return {
        ...state,
        config: {
          ...payload,
        },
      };
    },

    setNavigationMenu(state, payload): any {
      return {
        ...state,
        navigationMenu: payload,
      };
    },

    setInitialPage(state, payload): any {
      return {
        ...state,
        initialPage: payload,
      };
    },

    setError(state, payload): any {
      return {
        ...state,
        error: payload,
      };
    },

    setFilterNote(state, payload): any {
      return {
        ...state,
        filterNote: payload,
      };
    },

    setfilterDateMaxRangeinMonths(state, payload): any {
      return {
        ...state,
        filterDateMaxRangeinMonths: payload,
      };
    },
  },
  effects: (dispatch): any => ({
    getPageConfiguration: async (payload): Promise<any> => {
      try {
        return await reportingService.getPageConfiguration(
          payload.connect_program_id,
          payload.page_key,
          {}
        );
      } catch (e) {
        console.error(e);
      }
    },

    getBlockDetail: async (payload) => {
      try {
        const { connect_program_id, page_key, block_id, query } = payload;
        return await reportingService.getBlockDetail(
          connect_program_id,
          page_key,
          block_id,
          {
            ...query,
            start_date: query.start_date
              ? moment(query.start_date)
                  .utc()
                  .add(1, 'days')
                  .startOf('day')
                  .toJSON()
              : moment()
                  .utc()
                  .subtract(2, 'months')
                  .startOf('day')
                  .toJSON(),
            end_date: query.end_date
              ? moment(query.end_date)
                  .utc()
                  .add(1, 'days')
                  .endOf('day')
                  .toJSON()
              : moment().utc().endOf('day').endOf('month').toJSON(),
          }
        );
      } catch (e) {
        console.error(e);
      }
    },

    getPublicConfig: async () => {
      try {
        const config = await reportingService.getPublicConfig();
        window.cookieStorage.setItem(
          'openid_configuration',
          JSON.stringify({
            PROGRAM_ID: config.openid_configuration.program_id,
            AUTH_URL: config.openid_configuration.login_url,
            CLIENT_ID: config.openid_configuration.client_id,
          })
        );

        dispatch.reportingStore.setPublicConfig(config);
      } catch (e) {
        console.error(e);
        dispatch.reportingStore.setError(e.message);
      }
    },

    downloadReportExport: async (payload) => {
      try {
        const { page_key, label, aggregation_page_key, query_index, aggregation, query } = payload;
        await reportingService.downloadReportExport({
          page_key,
          label,
          aggregation_page_key,
          query_index,
          aggregation,
          ...query,
          start_date: query.start_date
            ? moment(query.start_date)
                .utc()
                .add(1, 'days')
                .startOf('day')
                .toJSON()
            : moment()
                .utc()
                .subtract(2, 'months')
                .startOf('day')
                .toJSON(),
          end_date: query.end_date
            ? moment(query.end_date)
                .utc()
                .add(1, 'days')
                .endOf('day')
                .toJSON()
            : moment().utc().endOf('day').endOf('month').toJSON(),
        });
      } catch (e) {
        console.error(e);
        dispatch.reportingStore.setError(e.message);
      }
    },

    downloadTemplatedReportExport: async (payload) => {
      try {
        const { page_key, block_type, label, report_file, report_index, query } = payload;
        await reportingService.downloadTemplatedReportExport({
          label,
          page_key,
          block_type,
          report_file,
          report_index,
          ...query,
          start_date: query.start_date
            ? moment(query.start_date).toJSON()
            : moment().subtract(2, 'months').subtract(1, 'days').toJSON(),
          end_date: query.end_date
            ? moment(query.end_date).toJSON()
            : moment().subtract(1, 'days').toJSON(),
        });
      } catch (e) {
        console.error(e);
        dispatch.reportingStore.setError(e.message);
      }
    },

    showFilterNote: async (payload) => {
      try {
        dispatch.reportingStore.setFilterNote(payload);
      } catch (e) {
        console.error(e);
        dispatch.reportingStore.setError(e.message);
      }
    },

    applyFilterDateMaxRangeinMonths: async (payload) => {
      try {
        console.log(payload);
        dispatch.reportingStore.setfilterDateMaxRangeinMonths(payload);
      } catch (e) {
        console.error(e);
        dispatch.reportingStore.setError(e.message);
      }
    },
  }),
};
