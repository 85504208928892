import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
} from '@material-ui/core';

const Settings = () => {
  let history = useHistory();

  const handleClick = () => {
    history.push(`/${window.programConfig.program_id}/settings`);
  };

  return (
    <Button color="primary" onClick={handleClick}>
      <Typography>Settings</Typography>
    </Button>
  );
};

export default Settings;