import React from 'react';
import Button from '@material-ui/core/Button';

import { downloadHKAPIReportExport } from '../../services/actions.service';

class HKAPIReportExport extends React.Component {
  _onClick = async () => {
    const { toggleLoader, query, pageKey, customQueries, label } = this.props;
    try {
      toggleLoader();
      await downloadHKAPIReportExport({
        ...query,
        page_key: pageKey,
        ...customQueries,
        label,
      });
      toggleLoader();
    } catch (e) {
      toggleLoader();
      console.error(e);
    }
  };

  render() {
    return (
      <Button color="secondary" variant="contained" onClick={this._onClick}>
        Export
      </Button>
    );
  }
}

export default HKAPIReportExport;
