import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import stores from '../../../models/index.model';

import { voidTransactionBatchId } from '../../../services/transaction.service';

export const VoidTransactionButton = ({ data, onVoid }) => {
  const { connect_program_id } = useParams();

  /**
   * On click approve event handler.
   *
   * @returns {Void}
   */

  /**
   * Handle click update event handler.
   *
   * @param {Event} e
   */
  const handleClickVoid = async (e) => {
    const { transaction_batch_id, program_id } = data;

    if (window.confirm('Do you want to void this transaction?')) {
      await voidTransactionBatchId({
        transaction_batch_id,
        program_id,
      })
        .then((res) => {
          alert(res.message);
          window.location.reload();
        })
        .catch((err) => alert(err.message));
      console.log(program_id);
    }
  };

  return (
    <div>
      <Button
        style={{ padding: 8, margin: 4 }}
        color="red"
        variant="contained"
        size="small"
        onClick={handleClickVoid}
      >
        Void
      </Button>
    </div>
  );
};

VoidTransactionButton.defaultProps = {
  data: null,
  onVoid: () => {},
};

VoidTransactionButton.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default VoidTransactionButton;
