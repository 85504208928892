import { init, RematchRootState, RematchDispatch } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';

const loadingPlugin = createLoadingPlugin({});

interface RootModel {
  reportingStore: any;
  filterStore: any;
  uiStore: any;
  authStore: any;
  hkapiStore: any;
  userStore: any;
}

export const store = init({
  plugins: [loadingPlugin],
  models: {
    reportingStore: require('./reporting.model').default,
    authStore: require('./auth.model').default,
    // patientStore: require('./patient.model').default,
    // summaryStore: require('./summary.model').default,
    // transactionStore: require('./transaction.model').default,
    // hcpStore: require('./hcp.model').default,
    filterStore: require('./filter.model').default,
    // reportingStore: require('./reporting.model').default,
    uiStore: require('./ui.model').default,
    hkapiStore: require('./hkapi.model').default,
    userStore: require('./user.model').default,
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type iRootState = RematchRootState<RootModel>;
export default store;
