import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { sendPatientTnc } from '../../services/customer.service';
import { makeStyles } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const SendPatientTermsConditionsAction = ({ data }) => {
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const { program_id, patient_id } = data;

  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await sendPatientTnc({
        program_id: program_id,
        customer_id: patient_id
      })

      if (res) {
        alert(res.message);
        handleClose(true);
        setConfirmation(false);
      }
      setLoading(false);
    } catch(err) {
      alert(err.message);
      handleClose(false);
      setLoading(false);
    }
  }

  const onClick = async () => {
    setConfirmation(true);
  };

  const handleClose = (reload) => {
    setConfirmation(false);
    setErrorMessage(null);
    if (reload) {
      window.location.reload();
    }
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'Confirm sending of T&C to patient?' 
      children = {(
        <>
          <Button onClick={onSubmit} variant='contained'>
            OK
          </Button>
          <Button onClick={() => handleClose(false)} variant='contained'>
            Close
          </Button>
        </>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Send T&C</Button>
    </>
  )
}
export default SendPatientTermsConditionsAction;
