import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

const ListItemLink = (props) => {
  const classes = useStyles();

  return <Link {...props} className={classes.root} />;
};

export default ListItemLink;
