import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// import './config.js';

// import MTHPAppRouter from './routers/AppRouter';
// import StayonAppRouter from './connect/stayon/routers/AppRouter';
import AppRouter from './routers/AppRouter';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { platform, Header } from './components/ui/Theme';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay.component';

import reportingService from './services/reporting.service';
import AuthService from './services/auth.service';

// import registerServiceWorker from './registerServiceWorker'; // Research what does this function do.=

import rematchStores from './models/index.model';

import { unregister } from './serviceWorker';
import CookieStorage from './utilities/cookieStorage.utility';

declare global {
  interface Window {
    config: any;
    cookieStorage: any;
    apiClient: any;
    authService: any;
    programConfig: any;
  }
}

// Lets do globals for common utilities
window.apiClient = require('./utilities/apiClient.utility').default;
window.cookieStorage = new CookieStorage();
class Main extends React.Component {
  state = {
    loading: true,
    error: null,
  };

  async componentDidMount() {
    const [programConfig, error] = await reportingService.getPublicConfig();

    if (programConfig) {
      window.programConfig = programConfig;
      window.authService = new AuthService(window.programConfig.PROGRAM_ID);
    }

    this.setState({
      loading: false,
      error: error || false,
    });
  }

  render(): JSX.Element {
    if (this.state.loading) {
      return <LoadingOverlay />;
    }

    if (this.state.error) {
      return <div style={{ color: 'red' }}>{this.state.error}</div>;
    }

    const { programConfig = {} } = window;

    return (
      <React.StrictMode>
        <Header />
        <Provider store={rematchStores}>
          <MuiThemeProvider theme={platform(programConfig.program_metadata && programConfig.program_metadata.page_theme || {})}>
            <AppRouter />
          </MuiThemeProvider>
        </Provider>
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
