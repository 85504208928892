import _ from 'lodash';

import apiClient from '../utilities/apiClient.utility';

const productsUrl = `${window.config.CONNECT_URLS.products}`;

export const getProductList = async () => {
    const { result } = await apiClient.get(
      `${productsUrl}/${window.programConfig.program_id}/public/catalog`
    );

    if (result && result.catalog) {
      return result.catalog;
    }

    return [];
};
