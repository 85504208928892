import axios from 'axios';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
class ApiClient {
  async get(path, reqQuery) {
    await this.inspectAndRenewTokens();

    const response = await axios.get(path, {
      params: {
        ...reqQuery,
      },
    });
    return response.data;
  }

  // FIXME: should also be able to POST www-url-form-encoded
  async post(path, reqBody) {
    await this.inspectAndRenewTokens();
    try {
      const { data } = await axios.post(path, reqBody);
      return data;
    } catch ({ response }) {
      throw response.data;
    }
  }

  async put(path, reqBody) {
    await this.inspectAndRenewTokens();

    try {
      const { data } = await axios.put(path, reqBody);
      return data;
    } catch ({ response }) {
      throw response.data;
    }
  }

  async patch(path, reqBody) {
    await this.inspectAndRenewTokens();

    try {
      const { data } = await axios.patch(path, reqBody);
      return data;
    } catch ({ response }) {
      throw response.data;
    }
  }

  async download(path, reqQuery, filename) {
    await this.inspectAndRenewTokens();

    const response = await axios({
      method: 'get',
      url: path,
      params: reqQuery,
      responseType: 'blob',
    });
    const contentType = _.get(response, 'headers.content-type');
    if (contentType.includes(`application/vnd.openxmlformats`)) {
      filename = filename.replace('.csv', '.xlsx');
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ? filename : 'file.xlsx');
    document.body.appendChild(link);
    link.click();
  }

  async getFileURL(path) {
    const response = await axios({
      method: 'get',
      url: path,
      responseType: 'arraybuffer',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }

  async del(path) {
    await this.inspectAndRenewTokens();

    try {
      const { data } = await axios.delete(path);
      return data;
    } catch ({ response }) {
      throw response.data;
    }
  }

  async delStellar(path, payload) {
    await this.inspectAndRenewTokens();

    try {
      const { data } = await axios.delete(path, payload);
      return data;
    } catch ({ response }) {
      throw response.data;
    }
  }

  // FIX: no need to async synchronous methods
  // async setAuthenticationHeaders(token){
  setAuthenticationHeaders(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  unsetAuthenticationHeaders() {
    delete axios.defaults.headers.common.Authorization;
  }

  setLoggedInUser(loggedInUser, refreshUserToken) {
    this.loggedInUser = loggedInUser;
    if (refreshUserToken) {
      this.refreshUserToken = refreshUserToken;
    }

    if (loggedInUser && loggedInUser.access_token) {
      this.setAuthenticationHeaders(loggedInUser.access_token);
    }
  }

  async inspectAndRenewTokens() {
    if (!this.loggedInUser) {
      return console.log('Proceeding as anonymous user...');
    }

    // FIXME: change to date moment.js processing
    const today = Math.floor(Date.now() / 1000); // limit to 10 digits

    const accessToken = jwtDecode(this.loggedInUser.access_token);

    // checks if access token is expired.
    if (accessToken.exp <= today) {
      console.error(`Access token is expired. Checking Refresh Token...`);

      // NO NEED FOR THIS. JUST REFRESH THE TOKEN
      // checks if refresh token is expired.
      // if (refreshToken.exp <= today) {
      //   console.error(`Refresh token is expired.`);
      //   stores.dispatch.uiStore.showPopupModal({
      //     message: 'Refresh token is expired.',
      //     disableCloseButton: true,
      //     actions: [
      //       {
      //         label: 'Logout',
      //         onClick: () => {
      //           stores.dispatch.authStore.logout();
      //         },
      //       },
      //     ],
      //   });
      // }

      // renew access token.
      const renewedUser = await this.refreshUserToken();
      return this.setLoggedInUser(renewedUser);
    }

    return console.log('Access token still valid');
  }
}

export default new ApiClient();
