import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import MainPage from '../../containers/MainPage/MainPage.container';
import PublicRoute from './PublicRoute';
import LoginPage from '../LoginPage/LoginPage.container';

class ProtectedRoute extends Component {
  async componentDidMount() {
    await this.props.checkUser();
  }

  render() {
    const { isAuthenticated, path, component } = this.props;

    return isAuthenticated ? (
      <MainPage>
        <Route path={path} component={component} {...this.props} />
      </MainPage>
    ) : (
      <PublicRoute
        path="/:connect_program_id/login"
        component={LoginPage}
        exact
      />
    );
  }
}

const mapStateToProps = ({ authStore: { isAuthenticated } }) => {
  return {
    isAuthenticated,
  };
};

const mapDispatchToProps = ({ authStore: { checkUser } }) => {
  return {
    checkUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
