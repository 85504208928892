import React from 'react';

import {
  Card as MaterialCard,
  CardContent,
  Typography,
  withStyles,
  Grid,
  Button,
  Input,
  InputAdornment,
} from '@material-ui/core';
import {
  SaveAltTwoTone as SaveAlt,
  SearchTwoTone as Search,
} from '@material-ui/icons';
import './Card.style.scss';

import actionButtons from '../ActionButtons/index.actions.js';

export const Card = ({ children, cardClassName, cardContentClassName }) => (
  <MaterialCard className={cardClassName}>
    <CardContent className={cardContentClassName}>{children}</CardContent>
  </MaterialCard>
);

const dataCardStyles = (theme) => ({
  progress: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.875rem',
  },
  subtitle: {
    fontSize: '0.75rem',
    paddingBottom: 7,
    marginBottom: 20,
  },
  searchForm: {
    margin: '3px 15px 0 15px',
  },
});

export const DataCard = withStyles(dataCardStyles)(
  ({
    title,
    subtitle,
    children,
    isLoading = false,
    progressSize = 120,
    classes,
    cardClassName,
    cardContentClassName,
    onExport,
    dataCount = 0,
    onSearch,
    actions = [],
    history,
    match,
    dataLength = 0,
  }) => (
    <Card
      cardClassName={cardClassName}
      cardContentClassName={cardContentClassName}
    >
      <Grid container>
        <Grid item xs={12} container>
          {(title || subtitle) && (
            <Grid item xs={6}>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            container
            justify="flex-end"
            alignItems="flex-start"
          >
            {onExport && (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onExport()}
                disabled={dataCount < 1}
              >
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                Save as CSV
              </Button>
            )}
            {onSearch && (
              <form
                onSubmit={(e) => onSearch.onSubmit ? onSearch.onSubmit(e) : e.preventDefault()}
                className={classes.searchForm}
              >
                <Input
                  placeholder={onSearch.placeholder && onSearch.placeholder}
                  value={onSearch.defaultValue && onSearch.defaultValue}
                  onChange={(e) =>
                    onSearch.onInputChange && onSearch.onInputChange(e)
                  }
                  inputProps={{
                    spellCheck: false,
                    ...onSearch.inputProps,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  }
                />
              </form>
            )}
            {actions &&
              actions.length > 0 &&
              actions.map((action) => {
                const ActionButtonComponent = actionButtons[action];
                console.log(action);
                return <ActionButtonComponent key={action} history={history} match={match} dataLength={dataLength} />;
              })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Card>
  )
);

export const SummaryCard = (props) => (
  <DataCard {...props}>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {props.children}
    </Grid>
  </DataCard>
);
