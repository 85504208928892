import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Typography } from '@material-ui/core';

export const Chart = ({ options, title, subtitle, type, height }) => (
  <HighchartsReact
    highcharts={Highcharts}
    containerProps={{ style: { width: '100%' } }}
    options={{
      chart: {
        type,
        height,
      },
      title: {
        text: title,
      },
      subtitle: {
        text: subtitle,
      },
      ...options,
    }}
  />
);

export const LineGraph = ({
  title = '',
  subtitle = '',
  enableDataLabels = true,
  series,
  height,
  legend = {
    enabled: false,
    layout: 'horizontal',
  },
  yAxis = {
    label: 'Values',
    accessor: '',
  },
  xAxis = {
    label: 'Values',
    accessor: '',
  },
}) => {

  if (!series || (series && series.length === 0))
    return <Typography>No data found</Typography>;
  else
    return (
      <Chart
        title={title}
        subtitle={subtitle}
        type="line"
        height={height}
        options={{
          xAxis: {
            title: { text: xAxis.label },
            categories: series
              ? series.map((datum) => datum[xAxis.accessor])
              : [],
          },
          yAxis: {
            title: { text: yAxis.label },
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: enableDataLabels,
              },
              color:
                window.programConfig.program_metadata.page_theme
                  ?.secondary_color,
              marker: {
                fillColor:
                  window.programConfig.program_metadata.page_theme
                    ?.primary_color,
              },
            },
          },
          legend: {
            enabled: legend.enabled,
            layout: legend.layout,
          },
          series: [
            {
              data: series
                ? series.map((datum) => parseInt(datum[yAxis.accessor], 10))
                : [],
            },
          ],
        }}
      />
    );
};

export const ColumnChart = ({
  title = '',
  subtitle = '',
  enableDataLabels = true,
  series,
  height,
  legend = {
    enabled: true,
    layout: 'horizontal',
  },
  yAxis = {
    label: 'Values',
    accessor: '',
  },
  xAxis = {
    label: 'Values',
    accessor: '',
  },
}) => {
  /**
   * Build actual chart series based in series props.
   * 
   * @param {[Object]} rawSeries unprocessed series.
   * @returns {Object}
   */
  const buildSeries = (rawSeries) => {
    const res = rawSeries;
    const dateStrings = [...new Set(res.map((item) => item[xAxis.accessor]))];
    const brands = [...new Set(res.map((item) => item.brand_id))];
    const hash = {};
    let temp, keys = [], series = [];

    // Key value pair, sales per day for each brand, if no data found, add 0
    brands.forEach((brand) => {
      if (!hash[brand]) {
          hash[brand] = [];
      }
      
      for (let i = 0; i < dateStrings.length; i++) {
        temp = res.find((item) => { return item.brand_id === brand && item[xAxis.accessor] === dateStrings[i]; });
        if (temp) {
          hash[brand].push(temp[yAxis.accessor]);
        }
        else {
          hash[brand].push(0);
        }
      }
    });
    
    keys = Object.keys(hash);
    
    // Build actual series.
    keys.forEach((key) => {
      series.push({
        name: key,
        data: hash[key]
      });
    });

    return series;
  }
  if (!series || (series && series.length === 0))
    return <Typography>No data found</Typography>;
  else
    return (
      <Chart
        title={title}
        subtitle={subtitle}
        type="column"
        height={height}
        options={{
          xAxis: {
            title: xAxis.label,
            categories: [...new Set(series.map((item) => item[xAxis.accessor]))]
          },
          yAxis: {
            title: { text: yAxis.label },
          },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: enableDataLabels,
              },
            },
          },
          legend: {
            enabled: legend.enabled,
            layout: legend.layout,
          },
          series: buildSeries(series),
        }}
      />
    );
};
