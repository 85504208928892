import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { DataCard } from '../Card/Card.component';
import FormikForm from '../FormikForm/FormikForm.component';

const styles = (theme) => ({
  container: {
    marginTop: 20,
    width: 383,
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  label: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  formControl: {
    display: 'flex',
    width: '350px',
    marginTop: 10,
  },
  saveButton: {
    marginTop: 30,
  },
});

const PasswordField = withStyles(styles)(
  ({ label, field, disabled, classes }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const _handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const _handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={field.name}>{label}</InputLabel>
          <Input
            id={field.name}
            type={showPassword ? 'text' : 'password'}
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            onInput={field.onBlur}
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={_handleClickShowPassword}
                  onMouseDown={_handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <ErrorMessage
          name={field.name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="error"
              >
                {message}
              </Typography>
            );
          }}
        />
      </div>
    );
  }
);

class ResetPassword extends React.Component {
  _onSaveChanges = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const payload = values;
      const { postUpdatePassword, showPopupModal, reportingStore } = this.props;
      const { program_id } = window.programConfig;

      await postUpdatePassword(payload);

      showPopupModal({
        title: window.programConfig.program_name,
        message: 'Your password has been changed.',
        disableCloseButton: true,
        actions: [
          {
            label: 'Close',
            onClick: () =>
              this.props.history.push(
                `/${program_id}/${reportingStore.initialPage}`
              ),
          },
        ],
      });
      actions.resetForm();
    } catch (err) {
      console.error(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const {
      title,
      label,
      classes,
      reportingStore: { navigationMenu },
    } = this.props;

    return (
      <div
        className={classes.container}
        style={{
          ...(navigationMenu && navigationMenu.length > 0
            ? { padding: '0 35px', width: 452 }
            : {}),
        }}
      >
        {title && (
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        )}
        {label && (
          <Typography variant="subtitle1" className={classes.label}>
            {label}
          </Typography>
        )}

        <DataCard>
          <FormikForm
            onSubmitHandler={this._onSaveChanges}
            validationSchema={Yup.object().shape({
              old_password: Yup.string()
                .matches(
                  /^.{8,}$/,
                  'Your old password needs to be at least 8 characters long.'
                )
                .matches(
                  /.*[A-Z]/,
                  'Your old password needs to include at least one upper case English Letter.'
                )
                .matches(
                  /.*[a-z]/,
                  'Your old password needs to include at least one lower case English Letter.'
                )
                .matches(
                  /^.*[0-9]/,
                  'Your old password needs to include at least one number.'
                )
                .matches(
                  /^.*[@#$%^&+=!*()?]/,
                  'Your old password needs to include at least one special character.'
                )
                .required('Old Password is required'),
              password: Yup.string()
                .when('old_password', {
                  is: (old_password) => !!old_password,
                  then: Yup.string().notOneOf(
                    [Yup.ref('old_password')],
                    'Your new password should not match your old password.'
                  ),
                })
                .matches(
                  /^.{8,}$/,
                  'Your new password needs to be at least 8 characters long.'
                )
                .matches(
                  /.*[A-Z]/,
                  'Your new password needs to include at least one upper case English Letter.'
                )
                .matches(
                  /.*[a-z]/,
                  'Your new password needs to include at least one lower case English Letter.'
                )
                .matches(
                  /^.*[0-9]/,
                  'Your new password needs to include at least one number.'
                )
                .matches(
                  /^.*[@#$%^&+=!*()?]/,
                  'Your new password needs to include at least one special character.'
                )
                .required('New Password is required'),
              confirm_password: Yup.string()
                .when('old_password', {
                  is: (old_password) => !!old_password,
                  then: Yup.string().notOneOf(
                    [Yup.ref('old_password')],
                    'Confirm password should not match your old password.'
                  ),
                })
                .when('password', {
                  is: (password) => !!password,
                  then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Confirm password should match your new password.'
                  ),
                })
                .matches(
                  /^.{8,}$/,
                  'Confirm password needs to be at least 8 characters long.'
                )
                .matches(
                  /.*[A-Z]/,
                  'Confirm password needs to include at least one upper case English Letter.'
                )
                .matches(
                  /.*[a-z]/,
                  'Confirm password needs to include at least one lower case English Letter.'
                )
                .matches(
                  /^.*[0-9]/,
                  'Confirm password needs to include at least one number.'
                )
                .matches(
                  /^.*[@#$%^&+=!*()?]/,
                  'Confirm password needs to include at least one special character.'
                )
                .required('Confirm Password is required'),
            })}
            customSubmitText="Save Changes"
          >
            <Field
              name="old_password"
              component={PasswordField}
              type="password"
              label="Old Password"
              required
            />
            <Field
              name="password"
              component={PasswordField}
              type="password"
              label="New Password"
              required
            />
            <Field
              name="confirm_password"
              component={PasswordField}
              type="password"
              label="Confirm Password"
              required
            />
          </FormikForm>
        </DataCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reportingStore: state.reportingStore,
});

const mapDispatchToProps = ({ userStore, uiStore }) => {
  return {
    ...userStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetPassword));
