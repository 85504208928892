import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';

const FormikForm = ({
  buttonClassName,
  buttonSize,
  initialValues = {},
  onSubmitHandler,
  formFields,
  validationSchema,
  customSubmitText,
  children,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      render={(props) => {
        return (
          <Form>
            {children}

            {(formFields || children) && (
              <Button
                className={buttonClassName}
                size={buttonSize}
                type="submit"
                disabled={!props.dirty || !props.isValid || props.isSubmitting}
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                fullWidth
              >
                {customSubmitText || 'SUBMIT'}
              </Button>
            )}
          </Form>
        );
      }}
    />
  );
};

export default FormikForm;
