import apiClient from '../utilities/apiClient.utility';
const storageUrl = `${window.config.CONNECT_URLS.storage}`;

export const upload = async (uploadData: any, connectProgramId: string) => {
  try {
    const fileData = new FormData();
    fileData.append('file', uploadData);
    const response =  await apiClient.post(
      `${storageUrl}/${connectProgramId}/upload`,
      fileData
    );
    return response;
  } catch (e) {
    return "No content"
  }
}
