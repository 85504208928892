import React from 'react';
import Button from '@material-ui/core/Button';
import { extendPatientTransactionCode } from '../../services/transaction.service';
import { TRANSACTIONS_CONSTANT } from '../../constants/common';

const extendTransactionCode = ({ data }) => {
    const { promo_code, transaction_code_id, program_id, voided_at, transaction_status } = data;

    const onClick = async () => {
        if (window.confirm(`Do you want to extend the validity of this code ${promo_code}?`)) {
            await extendPatientTransactionCode({
                program_id,
                transaction_code_id,
            })
            .then((res) => {
                alert(res.message);
                window.location.reload();
            })
            .catch((err) => alert(err.message));
        }
    };

    if (voided_at || transaction_status === TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.VOIDED) {
        return <></>;
    }

    return (
        <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Extend Transaction Code</Button>
    )
}

export default extendTransactionCode;
