import React, { useEffect, useState } from 'react';
import { manuallyCreatePatient } from '../../services/customer.service';
import { getAllDoctors, getFieldList } from '../../services/user.service';
import { Button, makeStyles, TextField, Typography, Grid, Select, MenuItem, InputLabel } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const AddPatientAction = ({ data }) => { 
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorInputMessage, setErrorInputMessage] = useState(null);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 10,
      width: '100%'
    },
    select: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  const [patientDetails, setPatientDetails] = useState({
    mobile_number: '',
    email_address: '',
    patient_name: '', 
    registered_by: '',
    hospital_name: '',
    dispensing_channel: '',
    scheme: '',
    dosage: '',
    sku: '',
    diagnosis: '',
    mykad_number: '',
    chosen_language: '',
    treating_physician: ''
  });
  const [listOfDoctors, setListOfDoctors] = useState();
  const [listOfDispensingChannel, setListOfDispensingChannel] = useState();
  const [listOfHospital, setListOfHospital] = useState();
  const { match } = data;
  const programId = match.params.connect_program_id;
  const pageKey = match.params.page_key;
  let patientEnv = 'develop';
  if (programId) {
    const splittedProgramId = programId.split('-');
    if (splittedProgramId && splittedProgramId.length) {
      patientEnv = splittedProgramId.pop();
    }
  }

  useEffect(() => {
    const getDoctors = async () => {
      const { result } = await getAllDoctors({
        program_id: `one-novartis-${patientEnv}`
      });

      result.unshift({ label: 'NA', value: 'NA' });
      setListOfDoctors(result);
    }

    const getAllDispensingChannel = async () => {
      const { result } = await getFieldList({
        program_id: `one-novartis-${patientEnv}`,
        name: 'outlet_name'
      });

      const dispensingChannel = result.field_type_options.select_options;
      dispensingChannel.unshift({ label: 'NA', value: 'NA' });

      setListOfDispensingChannel(dispensingChannel);
    }

    const getAllHospital = async () => {
      const { result } = await getFieldList({
        program_id: `one-novartis-${patientEnv}`,
        name: 'hospital'
      });

      const hospitals = result.field_type_options.select_options;
      hospitals.unshift({ label: 'NA', value: 'NA' });

      setListOfHospital(hospitals);
    }

    getDoctors();
    getAllDispensingChannel();
    getAllHospital();
  }, []);

  const onClick = async () => {
    setOpenModal(true);
  };

  const onSubmit = async() => {
    try {
      setLoading(true);
      const { result, message } = await manuallyCreatePatient({
        program_id: programId,
        payload: {
          parent_program_id: `one-novartis-${patientEnv}`,
          mobile_number: patientDetails.mobile_number,
          registered_by_user: patientDetails.registered_by_user || 'NA', 
          patient_name: patientDetails.patient_name,
          email_address: patientDetails.email_address,
          mykad_number: patientDetails.mykad_number,
          scheme: patientDetails.scheme,
          existing_scheme: `SPT-${patientDetails.scheme.split('+')[0]}`,
          pap_id: patientDetails.pap_id,
          selected_dosage: patientDetails.dosage,
          selected_dosage_label: patientDetails.dosage,
          selected_sku: patientDetails.sku,
          selected_sku_label: patientDetails.sku,
          diagnosis: patientDetails.diagnosis,
          dispensing_channel: patientDetails.dispensing_channel,
          hospital_name: patientDetails.hospital_name,
          chosen_language: patientDetails.chosen_language,
          dosage_frequency: '',
          has_non_malaysian_number: patientDetails.email_address ? true: false,
          manually_enrolled: true,
          is_existing_patient: pageKey.includes('not-yet-enrolled'),
          selected_program: programId,
          treating_physician: patientDetails.treating_physician
        }
      });

      if (result || message) {
        setOpenModal(false);
        setConfirmation(true);
      } else {
        setPatientDetails({
          mobile_number: '',
          email_address: '',
          patient_name: '', 
          registered_by: '',
          hospital_name: '',
          dispensing_channel: '',
          scheme: '',
          dosage: '',
          sku: '',
          diagnosis: '',
          mykad_number: '',
          chosen_language: '',
          treating_physician: ''
        });
      }
    } catch(err) {
      if (err && err.inputs && err.message === 'ValidationError') {
        let errorMessage = ''
        for (const input in err.inputs) {
          errorMessage += `${err.inputs[input]} `
        }
        setErrorMessage(errorMessage);
      } else if (err && err.message) {
        alert(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    if (name === "registered_by_user") {
      if (listOfDoctors && listOfDoctors.length) {
        const doctor = listOfDoctors.find((doctor) => doctor.id === value);
        if (doctor) {
          setPatientDetails(prevState => ({
            ...prevState,
            ['treating_physician']: doctor.full_name,
          }));
        }
      }
    }

    setPatientDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <TextField 
              required={true}
              label='Full Name'
              name='patient_name' 
              defaultValue={patientDetails.patient_name}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
        </Grid>
        <Grid item xs={6}>
            <TextField 
              required={true}
              label='PAP ID'
              name='pap_id'
              defaultValue={patientDetails.pap_id}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
        </Grid>
      </Grid>
      <Grid item
        container
        xs={12}
        spacing={2}>
        <Grid item xs={6}>
          <TextField  
              label='Mobile Number'
              required={true}
              name='mobile_number' 
              defaultValue={patientDetails.mobile_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput} 
              placeholder='60XXXXXXXXX'
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
              required={true}
              label='MyKad Number'
              name='mykad_number' 
              defaultValue={patientDetails.mykad_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid 
          item xs={12}
        >
          <TextField  
            label='Email Address'
            name='email_address' 
            defaultValue={patientDetails.email_address}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <InputLabel>Enrolled By</InputLabel>
            <Select 
              required={true}
              label='Enrolled By'
              name="registered_by_user"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              {
                listOfDoctors && listOfDoctors.length ? listOfDoctors.map(listOfDoctor => {
                  return (
                    <MenuItem className={classes.menuItem} key={listOfDoctor.id} value={listOfDoctor.id}>
                      {listOfDoctor.full_name}
                    </MenuItem>
                  )
                }) : <></>
              }
            </Select>
        </Grid>
        <Grid item xs={6}>
            <InputLabel>Chosen Language</InputLabel>
            <Select 
              required={true}
              label='Chosen Language'
              name="chosen_language"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              <MenuItem className={classes.menuItem} key="en" value="en">
                English
              </MenuItem>
              <MenuItem className={classes.menuItem} key="my" value="my">
                Malay
              </MenuItem>
              <MenuItem className={classes.menuItem} key="cn" value="cn">
                Chinese
              </MenuItem>
            </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Dosage</InputLabel>
          <Select 
            required={true}
            label='Dosage'
            name="dosage"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
          >
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_1-box" value="fraizeron_150mg_1-box">
                Fraizeron 150mg
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_300mg_1-box" value="fraizeron_300mg_1-box">
                Fraizeron 300mg
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_75mg_1-box" value="fraizeron_75mg_1-box">
                Fraizeron 75mg
              </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>SKU</InputLabel>
          <Select 
              required={true}
              label='SKU'
              name="sku"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_auto_injector_1" value="fraizeron_150mg_auto_injector_1">
                  Fraizeron pre-filled pen 150mg (x1)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_auto_injector_2" value="fraizeron_150mg_auto_injector_2">
                Fraizeron pre-filled pen 150mg (x2)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_300mg_auto_injector_1" value="fraizeron_300mg_auto_injector_1">
                Fraizeron pre-filled pen 300mg (x1)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_vial_1" value="fraizeron_150mg_vial_1">
                Fraizeron 150mg vial (X1)
              </MenuItem>
            </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Scheme</InputLabel>
          <Select 
            required={true}
            label='Scheme'
            name="scheme"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
          >
              <MenuItem className={classes.menuItem} key="7+5" value="7+5">
                7+5
              </MenuItem>
              <MenuItem className={classes.menuItem} key="4+2" value="4+2">
                4+2
              </MenuItem>
              <MenuItem className={classes.menuItem} key="5+7" value="5+7">
                5+7
              </MenuItem>
              <MenuItem className={classes.menuItem} key="3+3" value="3+3">
                3+3
              </MenuItem>
              <MenuItem className={classes.menuItem} key="6+6" value="6+6">
                6+6
              </MenuItem>
              <MenuItem className={classes.menuItem} key="8+4" value="8+4">
                8+4
              </MenuItem>
            </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Diagnosis</InputLabel>
          <Select 
              required={true}
              label='Diagnosis'
              name="diagnosis"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              <MenuItem className={classes.menuItem} key="Plaque Psoriasis (PsO)" value="Plaque Psoriasis (PsO)">
                Plaque Psoriasis (PsO)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Psoriatic Arthritis (PsA)" value="Psoriatic Arthritis (PsA)">
                Psoriatic Arthritis (PsA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Juvenile Psoriatic Arthritis (JPsA)" value="Juvenile Psoriatic Arthritis (JPsA)">
                Juvenile Psoriatic Arthritis (JPsA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Enthesitis-Related Arthritis (ERA)" value="Enthesitis-Related Arthritis (ERA)">
                Enthesitis-Related Arthritis (ERA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Ankylosing Spondylitis (AS)" value="Ankylosing Spondylitis (AS)">
                Ankylosing Spondylitis (AS)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Non-radiographic axial spondyloarthritis (nr-axSpA)" value="Non-radiographic axial spondyloarthritis (nr-axSpA)">
                Non-radiographic axial spondyloarthritis (nr-axSpA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Hidradenitis Suppurativa (HS)" value="Hidradenitis Suppurativa (HS)">
                Hidradenitis Suppurativa (HS)
              </MenuItem>
            </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Hospital</InputLabel>
          <Select 
            required={true}
            label='Hospital'
            name="hospital_name"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
          >
             {
                listOfHospital && listOfHospital.length ? listOfHospital.map(hospital => {
                  return <MenuItem className={classes.menuItem} key={hospital.value} value={hospital.value}>
                  {hospital.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Dispensing Channel</InputLabel>
          <Select 
              required={true}
              label='Dispensing Channel'
              name="dispensing_channel"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
              {
                listOfDispensingChannel && listOfDispensingChannel.length ? listOfDispensingChannel.map(dispensingChannel => {
                  return <MenuItem className={classes.menuItem} key={dispensingChannel.value} value={dispensingChannel.value}>
                  {dispensingChannel.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
        </Grid>
      </Grid>
    </>
  );

  const handleClose = (reload) => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setPatientDetails({
      mobile_number: '',
      email_address: '',
      patient_name: '', 
      registered_by: '',
      hospital_name: '',
      dispensing_channel: '',
      scheme: '',
      dosage: '',
      sku: '',
      diagnosis: '',
      mykad_number: '',
      chosen_language: '',
      treating_physician: ''
    });

    if (reload) {
      window.location.reload();
    }
  }

  const formButtons = (
    <>
      <Button onClick={() => handleClose(false)} variant='contained'>
        Close
      </Button>
      <Button onClick={onSubmit} variant='contained' color='secondary'>
        Submit
      </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <div style={{ "width": "1000px" }}>
     <Dialog 
      className="add-patient-dialog"
      key='create-patient'
      open={openModal}
      title = 'Create Patient'
      content = {formContent} 
      children = {formButtons} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'Patient successfully created!' 
      children = {(
        <Button onClick={() => handleClose(true)} variant='contained'>
          Close
        </Button>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(true)} 
      />
     <Button variant="contained" color="primary" onClick={onClick} 
     style={{ marginRight: 8 }}>
      + ADD PATIENT</Button>
    </div>
  )
}

export default AddPatientAction;
