import React from 'react';

import { connect } from 'react-redux';

import {
  Grid,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import CookieStorage from '../../utilities/cookieStorage.utility';

const LoginPage = (props) => {
  const cookieStorage = new CookieStorage();
  const realmId =
  window.programConfig.keycloak_connect_configuration['realm'];
  if(cookieStorage.getItem(`oidc.user:${window.programConfig.keycloak_connect_configuration['auth-server-url']}/realms/${realmId}:${window.programConfig.keycloak_connect_configuration['client_id']}`) == null) {
    cookieStorage.clear();
  }
  if (props.isLoading) {
    return <div>LOADING...</div>;
  }

  const handleLogin = props.login;
  return (
    <Grid
      container
      direction={'row'}
      alignItems={'center'}
      justify={'center'}
      style={{
        backgroundColor: window.programConfig?.program_metadata?.page_theme?.background_color,
        height: '100%',
      }}
    >
      <Grid item md={3} sm={6} xs={9}>
        <Card>
          <div style={{ padding: '50px 50px 0 50px' }}>
            <img
              src={window.programConfig?.program_metadata?.page_theme?.logo}
              alt={`logo`}
              style={{ width: '100%' }}
            />
          </div>
          <CardContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              style={{ marginTop: 12 }}
            >
              <Button
                color="secondary"
                type="button"
                size="large"
                variant="contained"
                fullWidth
                onClick={handleLogin}
              >
                {/* { isLoading && (
                  <CircularProgress
                    color="secondary"
                    size={16}
                    thickness={5}
                    style={{ marginRight: 8 }}
                  />
                )} */}
                Login
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  loading,
}): any => {
  return {
    isLoading: loading.models.reportingStore,
  };
};

const mapDispatchToProps = ({
  authStore: { signinRedirect },
}): any => {
  return {
    login: signinRedirect,
  };
};

const ConnectedLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null
  // { context: rematchContext }
)(LoginPage);

export default ConnectedLoginContainer;
