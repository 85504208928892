import moment from 'moment-timezone';
import _ from 'lodash';

import apiClient from '../utilities/apiClient.utility';
import stores from '../models/index.model';

const reportingUrl = `${window.config.CONNECT_URLS.reporting}`;

export default {
  getPageConfiguration: async (connectProgramId, pageKey, reqQuery) => {
    const { result } = await apiClient.get(
      `${reportingUrl}/${connectProgramId}/dashboard/pages/${pageKey}`,
      reqQuery
    );
    if (result.blocks) {
      result.blocks = _.orderBy(result.blocks, ['order'], ['asc']);
    }
    return result;
  },

  getBlockDetail: async (connectProgramId, pageKey, blockId, reqQuery) => {
    const { result } = await apiClient.get(
      `${reportingUrl}/${connectProgramId}/dashboard/pages/${pageKey}/block/${blockId}`,
      reqQuery
    );
    return result;
  },
  // exportApprovedCustomers: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/customers/approved`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportEligibleCustomers: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/customers/eligible`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportEnrolledCustomers: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/customers/enrolled`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportEnrolledHcp: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/hcps/enrolled`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportTransactingCustomers: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/customers/transacting`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportTransactingHcp: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/hcps/transacting`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportPotentiallyEligibleCustomers: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/customers/potentially-eligible`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // exportProductList: async (reqQuery, filename) => {
  //   await apiClient.download(`${reportingUrl}/products`, reqQuery, filename);
  // },
  // exportTransactionList: async (reqQuery, filename) => {
  //   await apiClient.download(
  //     `${reportingUrl}/transactions`,
  //     reqQuery,
  //     filename
  //   );
  // },
  // getApprovedCustomers: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/customers/approved`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getEnrolledCustomers: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/customers/enrolled`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getEnrolledHCPs: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/hcps/enrolled`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getEligibleCustomers: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/customers/eligible`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getHologramImage: async (reqParam) => {
  //   const result = await apiClient.getFileURL(
  //     `${reportingUrl}/transactions/transaction-line-item/${reqParam}/hologram-image`
  //   );
  //   return result;
  // },
  // getPotentiallyEligibleCustomers: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/customers/potentially-eligible`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getTransactingCustomers: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/customers/transacting`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getTransactingHCPs: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/hcps/transacting`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getProductList: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/products`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // getTransactionList: async (reqQuery) => {
  //   const { result } = await apiClient.get(
  //     `${reportingUrl}/transactions`,
  //     reqQuery
  //   );
  //   return result;
  // },
  // putApproveFreeGoodCode: async (reqParam) => {
  //   const { result } = await apiClient.put(
  //     `${transactionUrl}/transaction-code/${reqParam}/approve`,
  //     null
  //   );
  //   return result;
  // },
  getPublicConfig: async (): Promise<any> => {
    try {
    // const url = getConfigBaseUrl(programId);
      const programId = window.location.pathname.split('/')[1];

      if (!programId) {
        stores.dispatch.uiStore.showPopupModal({
          message: 'Need to specify program_id',
        });
        return [null, null];
      }

      const result = await apiClient.get(`${window.config.CONNECT_URLS.programs}/${programId}/config`, {program_id: programId});
      return [result, null];
    } catch(e) {
      console.error(e);
      return [null, e.message]
    }
  },

  downloadReportExport: async (payload) => {
    return apiClient.download(
      `${reportingUrl}/${window.programConfig.program_id}/dashboard/export`,
      payload,
      `${payload.label} ${moment().format('YYYY_MM_DD_HH_mm_ss')}.csv`
    );
  },

  downloadTemplatedReportExport: async (payload) => {
    return apiClient.download(
      `${reportingUrl}/${window.programConfig.program_id}/dashboard/export/templated`,
      payload,
      `${payload.label} ${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`
    );
  },
};
