import { postUpdatePassword } from '../services/user.service';
import stores from './index.model';
import generateErrorMessage from "../utilities/errorMessage.utility";

export default {
  state: {},
  reducers: {},
  effects: (dispatch): any => ({
    postUpdatePassword: async (payload): Promise<any> => {
      try {
        return await postUpdatePassword(payload);
      } catch (e) {
        console.error(e);
        stores.dispatch.uiStore.showPopupModal({
          title: window.programConfig.program_name,
          message: generateErrorMessage(e),
          actions: [],
        });
        throw e;
      }
    },
  }),
};
