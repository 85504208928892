import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import mustache from 'mustache';

import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  Typography,
  CssBaseline,
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import {
  ExitToAppTwoTone as ExitToApp,
  Menu as MenuIcon,
  ArrowDropDown,
} from '@material-ui/icons';

// import StayOnNavigation from '../../../stayon/components/Navigation/Navigation.component';
// import MyCareNavigation from '../../../mycare/components/Navigation/Navigation.component';
import Filter from '../../components/Filter/Filter.component';
import actionButtons from '../../components/ActionButtons/index.actions.js';

// import ActiveFiltersCard from '../ui/ActiveFilters';

import Sidebar from '../Sidebar/index.js';

// import packageJson from '../../../../../package.json';

// import { rematchContext } from '../../models/index.model';

// const StayOnLogo = '../stayOn-Logo-white.png';
// const MyCareLogo = '../MyCare_Horizontal Logo.png';

// const styles = theme => ({
//   root: {
//     display: 'flex',
//     flexGrow: 1,
//     minHeight: '100%',
//     position: 'relative',
//     zIndex: 1,
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     backgroundColor: theme.palette.primary.main,
//   },
//   drawerPaper: {
//     position: 'relative',
//     width: 210,
//   },
//   content: {
//     backgroundColor: theme.palette.background.default,
//     flexGrow: 1,
//     minWidth: 0, // So the Typography noWrap works
//     // padding: theme.spacing.unit * 1,
//   },
//   toolbar: theme.mixins.toolbar,
//   version: {
//     display: 'none',
//     // fontSize: '0.8em',
//     // margin: '5px 0 0 15px',
//     // color: 'transparent',
//   },
// });

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100%',
    position: 'relative',
    zIndex: 1,
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      'flex-direction': 'column-reverse',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    minWidth: 0,
    // padding: theme.spacing(1),
  },
  version: {
    display: 'none',
    // fontSize: '0.8em',
    // margin: '5px 0 0 15px',
    // color: 'transparent',
  },
  contentOnly: {
    padding: '0 100px',
  },
  actionsButton: {
    color: '#ffffff',
  },
  dashboardTitle: {
    '& a': {
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
}));

const MainPage = ({ children, reportingStore, authStore, filterStore }) => {
  const {
    programConfig: {
      program_metadata: { dashboard = null },
    },
  } = window;
  const { navigationMenu } = reportingStore;
  const { userRoles, authenticatedUser } = authStore;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const replaceText = (text, params) => {
    return mustache.render(text, params);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container alignItems="center">
            <Grid item xs={6} container alignItems="flex-end" className={classes.dashboardTitle}>
              {/*<img
                              src={logo}
                              alt={` ${DASHBOARD_TYPE} logo`}
                              width={logoWidth}
                            />*/}
              <Link to={`/${window.programConfig.program_id}/${reportingStore.initialPage}`}>
                <h2>{window.programConfig.program_name} Dashboard</h2>
              </Link>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              {dashboard && dashboard.actions && userRoles.some((role) => dashboard.actions_roles.includes(role)) ? (
                <React.Fragment>
                  <Button
                    className={classes.actionsButton}
                    onClick={handleMenuClick}
                  >
                    {dashboard.actions_label ? replaceText(dashboard.actions_label, authenticatedUser.profile).split('@')[0] : 'Menu'}
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={handleMenuClose}
                  >
                    {dashboard.actions &&
                      dashboard.actions.map((action) => {
                        if (actionButtons[action]) {
                          const ActionButtonComponent = actionButtons[action];
                          return (
                            <MenuItem onClick={handleMenuClose}>
                              <ActionButtonComponent
                                key={action}
                              />
                            </MenuItem>
                          );
                        }
                      })}
                  </Menu>
                </React.Fragment>
              ) : (
                <Logout />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {navigationMenu && navigationMenu.length > 0 && (
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}

      {/* <Drawer
        anchor="left"
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.toolbar} />
        <Divider />
        {DASHBOARD_TYPE === 'stayon' && <StayOnNavigation />}
        {DASHBOARD_TYPE === 'mycare' && <MyCareNavigation />}
      </Drawer> */}

      {/* <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            {children}
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Filter />
          </Grid>
        </Grid>
      </main> */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {navigationMenu && navigationMenu.length > 0 ? (
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              {children}
            </Grid>
            {!filterStore.hideFilterSidebar && (
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <Filter />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            className={classes.contentContainer}
          >
            <Grid
              item
              container
              className={classes.contentOnly}
              xs={12}
              md={12}
              lg={12}
              xl={12}
            >
              {children}
            </Grid>
          </Grid>
        )}
      </main>
    </div>
  );
};

// REMATCH
const mapStateToPropsRematch = ({ filterStore }) => {
  return {
    filterStore,
  };
};
const mapDispatchToProps = ({ userStore, authStore }) => {
  return {
    ...userStore,
    ...authStore,
  };
};

const Logout = connect(
  mapStateToPropsRematch,
  mapDispatchToProps,
  null
)(
  class Logout extends React.Component {
    handleLogout = async () => {
      await this.props.logout();
    };

    render() {
      return (
        <Button color="primary" onClick={this.handleLogout}>
          <ExitToApp style={{ marginRight: 8, fill: '#fff' }} />
          <Typography style={{ color: '#fff' }}>Logout</Typography>
        </Button>
      );
    }
  }
);

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(MainPage);
