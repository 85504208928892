import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import reportingService from '../../services/reporting.service';
import moment from 'moment-timezone';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const ExportTableAction = ({ data }) => {
  const urlSearch = data.history.location.search;
  const querySearchObject = Object.fromEntries(new URLSearchParams(urlSearch));
  const [isLoading, setLoading] = useState(false);

  const exportTable = async() => {
    setLoading(true);
    try {
      await reportingService.downloadReportExport({
        page_key: data.match.params.page_key,
        query_index: 0,
        label: data.title,
        exportTable: true,
        start_date: data.start_date
          ? moment(data.start_date)
              .utc()
              .add(1, 'days')
              .startOf('day')
              .toJSON()
          : moment()
              .utc()
              .subtract(2, 'months')
              .startOf('day')
              .toJSON(),
        end_date: data.end_date
          ? moment(data.end_date)
              .utc()
              .add(1, 'days')
              .endOf('day')
              .toJSON()
          : moment().utc().endOf('day').endOf('month').toJSON(),
        ...querySearchObject,
      })
    } catch(err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Grid container justify="flex-end" style={{ marginBottom: 12 }}>
        <Button variant='contained' color='secondary' 
          onClick={exportTable}>
          <GetAppIcon></GetAppIcon>
          SAVE AS CSV
        </Button>
      </Grid>
    </>
  )

};

export default ExportTableAction;