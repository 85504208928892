import React, { useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { updateTransactionLineItem } from '../../services/transaction.service';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment';

const UpdateLineItem = ({ data }) => {
  const { transaction_line_item_id, meditag_number, program_id, patient_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [lineItemDetails, setLineItemDetails] = useState({
    security_label: meditag_number,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('FOC Collection status successfully updated!');
  const [isLoading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  let inputFileRef = useRef(null);

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  
  const handleClickAction = () => {
    setOpenModal(true);
    setModalTitle('Update Meditag Details');
  }

  const handleClose = () => {
    setOpenModal(false);
    setLineItemDetails({
        security_label: meditag_number,
    });
    setConfirmation(false);
    setErrorMessage(null);
    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }
 
  const onSubmit = async () => {
    try {
      setLoading(true);

      let imgUrl = undefined;
      if (selectedImage) {
        const { result } = await upload(blobToFile(selectedImage), program_id);
        imgUrl = result.url;
      }
      await updateTransactionLineItem(
        {
          connect_program_id: program_id,
          transaction_line_item_id,
          payload: {
            campaign_metadata: {
                security_label: lineItemDetails.security_label,
                hologram_image: imgUrl
            }
          },
        }
      )

      handleClose();

      setSuccessMessage('Transaction Line Item Updated!');
      setConfirmation(true);
    } catch(err) {
      setErrorMessage('Unable to update line item');
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setLineItemDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <FormControl className={classes.formControl} component={'span'}>
          <FormLabel className={classes.field}>
          <Typography>
              Transaction Line Item ID
          </Typography>
          <Typography>
          { transaction_line_item_id }
          </Typography>
          </FormLabel>
      </FormControl>
      <FormControl className={classes.formControl} component={'span'}>
          <FormLabel className={classes.field}>
          <Typography>
              Patient ID
          </Typography>
          <Typography>
          { patient_id }
          </Typography>
          </FormLabel>
      </FormControl>
      <FormControl className={classes.formControl} component={'span'}>
          <FormLabel >
          <Typography>
              Meditag Number
          </Typography>
          </FormLabel>
          <Input className={classes.field} component={'span'}
            required={true} 
            name='security_label'
            type='text' 
            defaultValue={meditag_number}  
            onChange={onFieldInput}
          />
      </FormControl>
      <FormControl className={classes.formControl} component={'span'}>
        <FormLabel>
          <Typography component={'span'}>
              Meditag Image
          </Typography>
        </FormLabel>
        {
          selectedImage && 
          <>
            <Grid container>
              <Grid item sm={8}>
                <img width={"250px"} src={window.URL.createObjectURL(new Blob([selectedImage]))} /> 
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { setSelectedImage(null) } }>Remove Image</Button>
              </Grid>
            </Grid>
          </>
        }
        <Button color="primary" variant="contained" onClick={()=> { inputFileRef.current.click() } }>Upload Image</Button>
        <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={inputFileRef} onChange={async (event) => {
          setSelectedImage(event.target.files[0]);
        }}></Input>
      </FormControl>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        <Button onClick={handleClose} variant='contained'>
            Close
        </Button>
        { !openConfirmation && 
        <Button onClick={onSubmit} variant='contained' color='secondary'>
            Submit
        </Button> }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='update-line-item'
      open={openModal} 
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      onSubmit={handleClickAction}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onSubmit={handleClose}
      onClose={handleClose}
      />
     <Button variant="contained" color='primary' onClick={handleClickAction} style={{ marginRight: 8 }}>
      <CreateIcon></CreateIcon>
     </Button>
    </>
  )
};

export default UpdateLineItem;