import React from 'react';
import moment from 'moment-timezone';
import MuiDatePicker from 'material-ui-pickers/DatePicker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns';

const DatePicker = (props) => {
  const {
    autoOk = true,
    clearable = false,
    format = 'yyyy-MM-dd',
    maxDateMessage = 'Date should not be after the current date',
    name,
    onChange,
    showTodayButton = false,
    value = moment().format(),
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className="mui-picker">
      <MuiDatePicker
        animateYearScrolling
        autoOk={autoOk}
        clearable={clearable}
        format={format}
        keyboard
        maxDateMessage={maxDateMessage}
        name={name}
        onChange={onChange}
        showTodayButton={showTodayButton}
        value={value}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
