import React, { useEffect, useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid, Link } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment'
import DatePicker from '../DatePicker/DatePicker.component';
import { reject, find, map, filter, isEmpty, forEach, toInteger } from 'lodash';
import { updateTransactionCodeDetails } from '../../services/transaction.service';

const UpdateUtilizationForm = ({ data }) => {
  const { patient_id, utilization_forms, program_id, transaction_code_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('Successfully updated!');
  const [isLoading, setLoading] = useState(false);
  let utilizationFormInputFileRef = useRef(null);
  const currentDate = new Date();
  const [defaultUtilizationForm, setDefaultUtilizationForm] = useState([{ key: 0, utilization_date: currentDate}]);
	const [utilizationUploadRows, setUtilizationUploadRows ] = useState([{ key: 0, utilization_date: currentDate}]);

  useEffect(() => {
    if (utilization_forms && utilization_forms.length) {
      const utilizationFormArray = JSON.parse(utilization_forms);

      setDefaultUtilizationForm(utilizationFormArray);
      setUtilizationUploadRows(utilizationFormArray);
    }
  }, [utilization_forms])

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  
  const handleClickAction = () => {
    setOpenModal(true);
  }

  const handleCloseSubmit = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }

  const removeUtilizationFormRow = async(key) => {		 
    const updatedUtilizationUpload = utilizationUploadRows.map(utilizationUploadRow => 
      utilizationUploadRow.key === key ? 
          {...utilizationUploadRow, filepath: null, type: null,
            webviewPath: null, webview_path: null, image_url: null } : utilizationUploadRow );
    setUtilizationUploadRows(updatedUtilizationUpload);
	}
 
  const onSubmit = async () => {
    try {
      setLoading(true);

      const filteredUtilizationUploadArray = filter(utilizationUploadRows, utilizationUploadRow => !isEmpty(utilizationUploadRow.image_url))

      let orderedKey = 0;
      let rearrangedKeyUtilizationUploadArray = [];
      for (const filteredUtilizationUpload of filteredUtilizationUploadArray) {
        rearrangedKeyUtilizationUploadArray.push({
          ...filteredUtilizationUpload,
          key: orderedKey
        });

        orderedKey++;
      }

      await updateTransactionCodeDetails({
        transaction_code_id,
        connect_program_id: program_id,
        payload: {
          utilization_forms: rearrangedKeyUtilizationUploadArray
        }
      })

      handleCloseSubmit();
      setSuccessMessage('Utilization form uploaded!');
      setConfirmation(true);
    } catch(err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }

  const utilizationFormContent = (utilizationForm) => {
    return (
      <>
          <FormControl className={classes.formControl} component={'span'}>
          <FormLabel>
            <Typography component={'span'}>
              Utilization Form
            </Typography>
          </FormLabel>
          {
            utilizationForm && utilizationForm.webviewPath ? 
            <>
              <Grid container>
                <Grid item sm={8}>
                  {
                      utilizationForm.type === 'application/pdf' ?
                      <Link href={utilizationForm.webviewPath} target='_blank'>{utilizationForm.webviewPath}</Link> : 
                      <>
                        <img width={"250px"} src={utilizationForm.webviewPath} /> 
                        <Link href={utilizationForm.webviewPath} target='_blank'>{utilizationForm.webviewPath}</Link>
                      </>
                  }
                </Grid>
                <Grid item sm={4}>
                  <Button color="primary" variant="contained" onClick={()=> {
                    removeUtilizationFormRow(utilizationForm.key)
                  }}>Remove File</Button>
                </Grid>
              </Grid>
            </> 
            : <></>
          }
          <Button color="primary" variant="contained" onClick={()=> { utilizationFormInputFileRef.current.click() } } id={`${utilizationForm.key}`}>Upload File</Button>
          <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={utilizationFormInputFileRef} onChange={async (event) => {
            setLoading(true);
            const targetEvent = event.target;
            const targetEventFiles = event.target.files;
            const { result } = await upload(blobToFile(event.target.files[0]), program_id);
  
            if (result && result.url) {
              const updatedUtilizationUpload = utilizationUploadRows.map(utilizationUploadRow => 
                utilizationUploadRow.key === utilizationForm.key ? 
                    {...utilizationUploadRow, filepath: targetEventFiles[0].name, type: targetEventFiles[0].type,
                      webviewPath: result.url, webview_path: result.url, image_url: result.url } : utilizationUploadRow );
              setUtilizationUploadRows(updatedUtilizationUpload);
            }
  
            setLoading(false);
          }}></Input>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel>
              <Typography>
                Utilization Date
              </Typography>
          </FormLabel>
          <DatePicker
            required={true}
            onChange={(e) => {
              let updatedUtilizationFomWithDate = [];
              for (const utilizationUploadRow of utilizationUploadRows) {
                if (toInteger(utilizationUploadRow.key) === toInteger(utilizationForm.key)) {
                  updatedUtilizationFomWithDate.push({
                    ...utilizationUploadRow,
                    utilization_date: e
                  })
                } else {
                  updatedUtilizationFomWithDate.push(utilizationUploadRow)
                }
              }

              setUtilizationUploadRows(updatedUtilizationFomWithDate);
            }}
            name='utilization_date'
            className={classes.field} 
            maxDate={currentDate}
            defaultValue={utilizationForm.utilization_date}
            value={utilizationForm ? utilizationForm.utilization_date : currentDate}
          />
        </FormControl>
      </>
      )
  }

  const formContent = (
    <>
      	{ utilizationUploadRows && 
				utilizationUploadRows.map((value, key) => {
          return (
            utilizationFormContent(value)
          )
        })
			}
      <Button variant='contained' onClick={() => setUtilizationUploadRows([...utilizationUploadRows, { key: `${utilizationUploadRows.length}` } ])}>Add+</Button>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        { openConfirmation ? 
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
        : 
        <>
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
          <Button onClick={onSubmit} variant='contained' color='primary'>
            Submit
          </Button> 
        </>
        }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='upload-utilization-form'
      open={openModal} 
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      onSubmit={onSubmit}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Button variant="contained" color="primary" onClick={() => handleClickAction()} style={{ marginRight: 8 }}>
        Upload
      </Button>
    </>
  )
};

export default UpdateUtilizationForm;