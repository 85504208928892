import React, { useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { updateUserChasCard } from '../../services/user.service';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment'
import { toNumber } from 'lodash';

const UpdateOverallPatientDetails = ({ data }) => {
  const ACTION_UPDATE = 'update';
  const ACTION_APPROVE = 'approve';
  const ACTION_REJECT = 'reject';
  const { program_id, patient_id, chasis_card_url, email_approval_img, user_tier } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [confirmApproveDisapprove, setConfirmApproveDisapprove] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [tier, setTier] = useState(toNumber(user_tier));
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('Successfully updated!');
  const [isLoading, setLoading] = useState(false);
  const [selectedChasisCard, setSelectedChasisCard] = useState(null);
  const [defaultChasisCard, setDefaultChasisCard] = useState(chasis_card_url);
  const [selectedEmailApproval, setSelectedEmailApproval] = useState(null);
  const [defaultEmailApproval, setDefaultEmailApproval] = useState(email_approval_img);
  const [action, setAction] = useState(null);
  let chasisCardInputFileRef = useRef(null);
  let emailApprovalInputFileRef = useRef(null);

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  
  const handleClickAction = (status) => {
    if (status === ACTION_UPDATE) {
      setOpenModal(true);
      setModalTitle('Update Overall Patient Details');
    } else {
      setAction(status);
      setConfirmApproveDisapprove(true);
    }
  }

  const handleCloseSubmit = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setDefaultChasisCard(chasis_card_url);
    setDefaultEmailApproval(email_approval_img);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }

  const onSubmitApproveDisapprove = async () => {
    try {
      setConfirmApproveDisapprove(false);
      if (!user_tier && action === ACTION_APPROVE) {
        alert("Cannot approve CHAS Card. User Tier must be assigned first.");
        return;
      }
      setLoading(true);
      await updateUserChasCard({
        program_id: program_id,
        id: patient_id,
        action: action,
      });
      let actionMessage = 'CHAS Card status has been updated'
      if (action === ACTION_REJECT) {
        actionMessage = 'CHAS Card has been rejected';
      }
      if (action === ACTION_APPROVE) {
        actionMessage = 'CHAS Card has been approved';
      }
      setSuccessMessage(actionMessage);
      setConfirmation(true);
    } catch(err) {
      setLoading(false);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  }
 
  const onSubmitUpdate = async () => {
    try {
      setLoading(true);

      let chasisCardImgUrl = undefined;
      let emailApprovalImgUrl = undefined;
      if (selectedChasisCard) {
        const { result } = await upload(blobToFile(selectedChasisCard), program_id);
        chasisCardImgUrl = result.url;
      }
      if (selectedEmailApproval) {
        const { result } = await upload(blobToFile(selectedEmailApproval), program_id);
        emailApprovalImgUrl = result.url;
      }

      await updateUserChasCard({
        program_id: program_id,
        id: patient_id,
        action: 'update',
        payload: {
          chasis_card_url: chasisCardImgUrl || undefined,
          email_proof: emailApprovalImgUrl || undefined,
          user_tier: toNumber(tier)
        }
      })

      handleCloseSubmit();

      setSuccessMessage('Overall Patient Updated!');
      setConfirmation(true);
    } catch(err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { value } = e.target;

    setTier(value);
  }

  const formContent = (
    <>
      <FormControl className={classes.formControl} component="span">
        <FormLabel>
          <Typography component={'span'}>
            Tier
          </Typography>
        </FormLabel>
        <RadioGroup row name="tier" onChange={onFieldInput} value={toNumber(tier)}>
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="1"
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label="2" 
          />
        </RadioGroup>
      </FormControl>
      <FormControl className={classes.formControl} component={'span'}>
        <FormLabel>
          <Typography component={'span'}>
            CHAS Card
          </Typography>
        </FormLabel>
        {
          selectedChasisCard ? 
          <>
            <Grid container>
              <Grid item sm={8}>
                <img width={"250px"} src={window.URL.createObjectURL(new Blob([selectedChasisCard]))} /> 
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { 
                  setSelectedChasisCard(null);
                  setDefaultChasisCard(null);
                }}>Remove Image</Button>
              </Grid>
            </Grid>
          </> 
          : 
          chasis_card_url && defaultChasisCard &&
            <Grid container>
              <Grid item sm={8}>
                <img width={"250px"} src={chasis_card_url} /> 
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { 
                  setDefaultChasisCard(null);
                }}>Remove Image</Button>
              </Grid>
            </Grid>
        }
        <Button color="primary" variant="contained" onClick={()=> { chasisCardInputFileRef.current.click() } }>Upload Image</Button>
        <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={chasisCardInputFileRef} onChange={async (event) => {
          setSelectedChasisCard(event.target.files[0]);
        }}></Input>
      </FormControl>
      <FormControl className={classes.formControl} component={'span'}>
        <FormLabel>
          <Typography component={'span'}>
              Email Approval
          </Typography>
        </FormLabel>
        {
          selectedEmailApproval ? 
          <>
            <Grid container>
              <Grid item sm={8}>
                <img width={"250px"} src={window.URL.createObjectURL(new Blob([selectedEmailApproval]))} /> 
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { 
                  setSelectedEmailApproval(null);
                  setDefaultEmailApproval(null);
                }}>Remove Image</Button>
              </Grid>
            </Grid>
          </> :  
          email_approval_img && defaultEmailApproval && 
            <Grid container>
              <Grid item sm={8}>
                <img width={"250px"} src={email_approval_img} /> 
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { setDefaultEmailApproval(null) } }>Remove Image</Button>
              </Grid>
            </Grid>
        }
        <Button color="primary" variant="contained" onClick={()=> { emailApprovalInputFileRef.current.click() } }>Upload Image</Button>
        <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={emailApprovalInputFileRef} onChange={async (event) => {
          setSelectedEmailApproval(event.target.files[0]);
        }}></Input>
      </FormControl>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        { openConfirmation ? 
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
        : 
        <>
          <Button onClick={handleClose} variant='contained'>
              Close
          </Button>
          <Button onClick={onSubmitUpdate} variant='contained' color='secondary'>
            Submit
          </Button> 
        </>
        }
    </>
  );

  const confirmApproveButtons = (
    <>
        <Button onClick={() => setConfirmApproveDisapprove(false)} variant='contained' color='secondary'>
          Close
        </Button>
        <Button onClick={onSubmitApproveDisapprove} variant='contained' color='primary'>
          Yes
        </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='update-overall-patient'
      open={openModal} 
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      onSubmit={handleClickAction}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Dialog 
      key='confirm-approve-disapprove'
      open={confirmApproveDisapprove}
      title = 'Confirmation'
      content = {`Are you sure to ${action} the CHAS Card?`}
      children = {confirmApproveButtons}
      onClose={() => setConfirmApproveDisapprove(false)}
      />
      <Button variant="contained" color="primary" onClick={() => handleClickAction(ACTION_UPDATE)} style={{ marginRight: 8 }}>
        <CreateIcon></CreateIcon>
      </Button>
    </>
  )
};

export default UpdateOverallPatientDetails;