import React from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import stores from '../../../models/index.model';

import { postTransactionBatchPurchaseApprove } from '../../../services/transaction.service';
import { postTransactionBatchPurchaseDisapprove } from '../../../services/transaction.service';

export const UpdateTransactionButton = ({ data, onUpdate }) => {
  const { connect_program_id } = useParams();

  /**
   * On click approve event handler.
   * 
   * @returns {Void}
   */
  const handleClickApprove = async () => {
    try {
      const { transaction_batch_id, user_id } = data;
      const res = await postTransactionBatchPurchaseApprove({
        connect_program_id,
        transaction_batch_id,
        user_id,
      });
      
      if (typeof onUpdate === 'function') {
        onUpdate(res);
      }
      alert(res.message);
    } catch (e) {
      alert(e.message);
    }
  }

  /**
   * On click disapprove event handler.
   * 
   * @returns {Void}
   */
  const handleClickDisapprove = async () => {
    try {
      const { transaction_batch_id, user_id } = data;
      const res = await postTransactionBatchPurchaseDisapprove({
        connect_program_id,
        transaction_batch_id,
        user_id,
      });
      
      if (typeof onUpdate === 'function') {
        onUpdate(res);
      }
      alert(res.message);
    } catch (e) {
      alert(e.message);
    }
  }
  /**
   * Handle click update event handler.
   * 
   * @param {Event} e 
   */
   const handleClickUpdate = async (e) => {
    stores.dispatch.uiStore.showPopupModal({
      title: 'Update confirmation modal',
      message: (
        <div>
          <p>
            Please confirm whether patient has submitted the correct set of prescriptions and receipts.
          </p>
        </div>
      ),
      disableCloseButton: true,
      actions: [
        {
          label: 'Approve',
          onClick: handleClickApprove,
        },
        {
          label: 'Disapprove',
          onClick: handleClickDisapprove,
        },
      ]
    })
  }

  return (
    <div>
      <Button
        style={{ padding: 8, margin: 4 }}
        color="red"
        variant="contained"
        size="small"
        onClick={handleClickUpdate}
      >
        Update
      </Button>
    </div>
  );
}

UpdateTransactionButton.defaultProps = {
  data: null,
  onUpdate: () => {}
}

UpdateTransactionButton.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default UpdateTransactionButton;
