import React from 'react';
import Button from '@material-ui/core/Button';
import { activateUser, deactivateUser } from '../../services/user.service';
import { USERS_CONSTANT } from '../../constants/common';

const UserActivateDeactivate = ({ data }) => {
    const { hcp_id, status, program_id } = data;
 
    const onClick = async () => {
        const toStatus = status === USERS_CONSTANT.STATUS.ACTIVE ? 'deactivate' : 'activate';
        if (window.confirm(`Do you want to ${toStatus} this user?`)) {
            if (toStatus === 'deactivate') {
                await deactivateUser({ id: hcp_id, program_id })
                .then((res) => {
                    alert(res.message);
                    window.location.reload();
                })
                .catch((err) => alert(err.message));
            } else {
                await activateUser({ id: hcp_id, program_id })
                .then((res) => {
                    alert(res.message);
                    window.location.reload();
                })
                .catch((err) => alert(err.message));
            }
        }
    };

    return (
        <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>{status === USERS_CONSTANT.STATUS.ACTIVE ? 'Deactivate' : 'Activate'}</Button>
    )
}

export default UserActivateDeactivate;
