// module.exports = {
//     ...require('./programs.constant'),
//     ...require('./transactions.constant'),
//     ...require('./reporting.constant'),
// };
module.exports.PROGRAMS_CONSTANT = {
    PROGRAM_ID: {
        MEDKAKI: {
            DEVELOP: 'pfsg-develop',
            TEST: 'pfsg-test', 
            STAGING: 'pfsg-staging',
            PRODUCTION: 'pfsg-production',
        }
    },
};

module.exports.REPORTING_CONSTANT = {
    PAGE_KEY: {
        TRANSACTION_DETAIL: 'transaction-detail'
    },
};

module.exports.TRANSACTIONS_CONSTANT = {
    TRANSACTION_TYPE: {
        PURCHASE: 'purchase',
        REDEEM: 'free_good',
    },
    TRANSACTION_STATUS: {
        PENDING: 'pending',
        APPROVED: 'approved',
        DISAPPROVED: 'disapproved',
        VOIDED: 'voided',
        FREE_GOODS_PENDING: 'free_goods_pending',
        FREE_GOODS_APPROVED: 'free_goods_approved',
        FREE_GOODS_REJECTED: 'free_goods_rejected',
        FREE_GOODS_CLAIMED: 'free_goods_claimed',
        FREE_GOODS_EXPIRED: 'free_goods_expired'
    },
};


module.exports.USERS_CONSTANT = {
    STATUS: {
        ACTIVE: 'active',
        DEACTIVATED: 'deactivated',
    }
};

