import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import { postTransactionBatchPurchaseApprove } from '../../services/transaction.service';
import { TRANSACTIONS_CONSTANT, PROGRAMS_CONSTANT } from '../../constants/common';
import { includes, values } from 'lodash';

class TransactionBatchPurchaseApprove extends React.Component {
  _onClick = async () => {
    const { transaction_batch_id, user_id } = this.props.data;
    const { connect_program_id } = this.props.match.params;
    if (window.confirm('Do you want to approve this transaction batch?')) {
      await postTransactionBatchPurchaseApprove({
        connect_program_id,
        transaction_batch_id,
        user_id,
      })
        .then((res) => {
          alert(res.message);
          window.location.reload();
        })
        .catch((err) => alert(err.message));
    }
  };

  render() {
    const { transaction_status, transaction_type } = this.props.data;
    const { connect_program_id } = this.props.match.params;
    const medkakiProgramIDList = values(PROGRAMS_CONSTANT.PROGRAM_ID.MEDKAKI);
    const isMedkaki = includes(medkakiProgramIDList, connect_program_id);

    if (isMedkaki) {
      const validStatuses = [TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.PENDING, TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.APPROVED];

      return (includes(validStatuses, transaction_status) && transaction_type === TRANSACTIONS_CONSTANT.TRANSACTION_TYPE.PURCHASE) ? (
        <Button variant="contained" onClick={this._onClick} style={{ marginRight: 8 }}>Approve</Button>
      ) : null;
    }

    if (includes(connect_program_id, 'pfz-global') || includes(connect_program_id, 'psplink-global')) {
      if (transaction_type === 'application' && transaction_status !== TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.PENDING) {
        return null;
      }
    }

    let alreadyVerified = false;
    if(includes(connect_program_id, 'nvid')&& transaction_status=='approved'){
      alreadyVerified = true;
    }

    return (
      <Button variant="contained" onClick={this._onClick} style={{ marginRight: 8 }}  disabled={alreadyVerified}>Approve</Button>
    );
  }
}
export default withRouter(TransactionBatchPurchaseApprove);
