import React from 'react';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  withStyles,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Markup } from 'interweave';

function stripHtmlTags(value) {
  return value?.replace(/<br\s*\/?>/gm, '\n').replace(/<[^>]*>?/gm, '');
}

class ProgramSupport extends React.Component {
  state = {
    isFieldsComplete: false,
    supportList: [],
    openCriteriaForm: false,
    selectedSupportIndex: null,
    supportChildren: {
      financial_criteria: '',
      program_detail: '',
    },
    editCriteriaIndex: null,
    enableEdit: false,
    originalState: null,
  };

  componentDidMount() {
    this.setState({ supportList: this.props.programDetail.support });
  }

  _onAddPatientType = () => {
    const { supportList } = this.state;
    const { programDetail } = this.props;
    const index = programDetail.support.length;

    supportList.push({
      value: `indication_${index + 1}`,
      label: null,
      detail: null,
      children: [],
    });

    programDetail.support = supportList;
    this.setState({ supportList });
    this.props.saveProgramDetail(programDetail);
  };

  _onPatientTypeFieldChange = (e, index) => {
    const { supportList } = this.state;
    const { programDetail } = this.props;
    const { value } = e.target;

    supportList[index].label = value;
    programDetail.support = supportList;
    this.setState({ supportList });
    this.props.saveProgramDetail(programDetail);
  };

  _handleToggleCriteriaForm = (index) => {
    this.setState({
      openCriteriaForm: !this.state.openCriteriaForm,
      ...(index !== null ? { selectedSupportIndex: index } : {}),
      supportChildren: {
        financial_criteria: '',
        program_detail: '',
      },
    });
  };

  _onCriteriaFieldChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    const { supportChildren } = this.state;

    if (name === 'program_detail') {
      value = value.replace(/(\r\n|\n|\r)/gm, '<br />');
    }
    supportChildren[name] = value;

    this.setState({ supportChildren });
    this._checkFieldsCompletion();
  };

  _saveFinancialCriteria = async () => {
    const { supportList } = this.state;
    const { programDetail } = this.props;
    const {
      selectedSupportIndex,
      supportChildren: { financial_criteria, program_detail },
      editCriteriaIndex,
    } = this.state;
    const index = supportList[selectedSupportIndex].children.length;

    if (editCriteriaIndex !== null) {
      supportList[selectedSupportIndex].children[
        editCriteriaIndex
      ].label = financial_criteria;
      supportList[selectedSupportIndex].children[
        editCriteriaIndex
      ].children[0].label = program_detail;
    } else {
      supportList[selectedSupportIndex].children.push({
        value: `indication_${selectedSupportIndex + 1}_income_${index + 1}`,
        label: financial_criteria,
        detail: null,
        children: [
          {
            value: `indication_${selectedSupportIndex + 1}_income_${
              index + 1
            }_detail_1`,
            label: program_detail,
          },
        ],
      });
    }
    programDetail.support = supportList;
    this.setState({
      openCriteriaForm: false,
      editCriteriaIndex: null,
      supportChildren: {
        financial_criteria: '',
        program_detail: '',
      },
      supportList,
    });
    this.props.saveProgramDetail(programDetail);
  };

  _editCriteria = (supportIndex, financialCriteriaIndex) => {
    const { supportList } = this.state;
    const { supportChildren } = this.state;
    const financialCiteria =
      supportList[supportIndex].children[financialCriteriaIndex];

    supportChildren.financial_criteria = financialCiteria.label;
    supportChildren.program_detail = financialCiteria.children[0].label;

    this.setState({
      supportChildren,
      selectedSupportIndex: supportIndex,
      editCriteriaIndex: financialCriteriaIndex,
      openCriteriaForm: true,
    });
  };

  _deleteCriteria = (supportIndex, financialCriteriaIndex) => {
    const { programDetail } = this.props;

    this.props.showPopupModal({
      message: 'Do you really want to delete Financial Criteria?',
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            programDetail.support[supportIndex].children.splice(
              financialCriteriaIndex,
              1
            );
            this.setState({ supportList: programDetail.support });
            this.props.saveProgramDetail(programDetail);
          },
        },
      ],
    });
  };

  _deletePatientType = (supportIndex) => {
    const { programDetail } = this.props;

    this.props.showPopupModal({
      message: 'Do you really want to delete Patient Type?',
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            programDetail.support.splice(supportIndex, 1);
            this.setState({ supportList: programDetail.support });
            this.props.saveProgramDetail(programDetail);
          },
        },
      ],
    });
  };

  _checkFieldsCompletion = () => {
    const { supportChildren } = this.state;
    let { isFieldsComplete } = this.state;

    // ENABLE/DISABLE save button
    for (const key in supportChildren) {
      if (supportChildren[key]) {
        isFieldsComplete = true;
        break;
      } else {
        isFieldsComplete = false;
      }
    }
    this.setState({ isFieldsComplete });
  };

  _updateProgramDetail = async () => {
    await this.props.updateProgramDetail();
    this.setState({ enableEdit: false });
  };

  _cancelUpdate = () => {
    const { programDetail } = this.props;
    const originalState = JSON.parse(this.state.originalState);

    this.props.showPopupModal({
      message: `Any changes you've done with ${programDetail.brand_name} will not be saved. Do you still want to cancel your edits?`,
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            this.setState({
              supportList: originalState,
              enableEdit: false,
            });
            this.props.saveProgramDetail({
              ...programDetail,
              support: originalState,
            });
          },
        },
      ],
    });
  };

  render() {
    const { isEdit, classes } = this.props;
    const {
      supportList,
      openCriteriaForm,
      supportChildren,
      isFieldsComplete,
      enableEdit,
    } = this.state;
    const disableActions = isEdit ? (enableEdit ? false : true) : false;

    return (
      <ExpansionPanel expanded={true} style={{ marginBottom: 50 }}>
        <ExpansionPanelSummary>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h6">
                Program Details
                {isEdit && enableEdit && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this._onAddPatientType}
                    style={{ marginLeft: 20 }}
                  >
                    + Add Patient Type
                  </Button>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="flex-start"
            >
              {isEdit ? (
                enableEdit ? (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this._updateProgramDetail}
                      disabled={supportList.length < 1}
                    >
                      Save
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this._cancelUpdate}
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        originalState: JSON.stringify(supportList),
                        enableEdit: true,
                      })
                    }
                  >
                    Edit
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this._onAddPatientType}
                >
                  + Add Patient Type
                </Button>
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <Grid container className={classes.gridRoot} spacing={8}>
          <Grid item container>
            {supportList < 1 ? (
              <div className={classes.emptyText}>
                <Typography variant="h6">
                  Please add patient type to complete program details
                </Typography>
              </div>
            ) : (
              supportList &&
              supportList.map((support, index1) => (
                <Grid
                  item
                  container
                  key={index1}
                  className={classes.patientTypeContainer}
                >
                  <Grid
                    item
                    container
                    className={classes.patientTypeRows}
                    xs={12}
                  >
                    <Grid item xs={disableActions ? 12 : 11}>
                      <TextField
                        name={support.value}
                        label={`Patient Type ${index1 + 1}`}
                        value={stripHtmlTags(support.label)}
                        onChange={(e) =>
                          this._onPatientTypeFieldChange(e, index1)
                        }
                        disabled={disableActions}
                        fullWidth
                      />
                    </Grid>
                    {!disableActions && (
                      <Grid item xs={1} style={{ display: 'flex' }}>
                        <Button
                          size="small"
                          className={classes.actionButton}
                          onClick={() => this._deletePatientType(index1)}
                        >
                          <Delete />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.patientTypeRows}
                    xs={12}
                  >
                    <Grid item xs={6}>
                      <InputLabel>Program Detail Options</InputLabel>
                    </Grid>
                    {!disableActions && (
                      <Grid
                        item
                        xs={6}
                        container
                        justify="flex-end"
                        alignItems="flex-start"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this._handleToggleCriteriaForm(index1)}
                          disabled={!support.label}
                        >
                          + Add Financial Criteria
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  {support.children < 1 ? (
                    <div className={classes.emptyText}>
                      <Typography variant="h6">
                        Please add financial criteria to complete program option
                      </Typography>
                    </div>
                  ) : (
                    <TableContainer>
                      <Table>
                        <TableHead className={classes.solidHeader}>
                          <TableRow>
                            <TableCell>Financial Criteria</TableCell>
                            <TableCell>Program Detail</TableCell>
                            {!disableActions && <TableCell></TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {support.children.map((children, index2) => (
                            <TableRow key={index2}>
                              <TableCell style={{ width: '25%' }}>
                                <Markup content={children.label} />
                              </TableCell>
                              <TableCell style={{ width: '65%' }}>
                                <Markup content={children.children[0]?.label} />
                              </TableCell>
                              {!disableActions && (
                                <TableCell
                                  key={index2}
                                  align="right"
                                  style={{ width: '10%' }}
                                >
                                  <Button
                                    size="small"
                                    onClick={() =>
                                      this._editCriteria(index1, index2)
                                    }
                                    className={classes.actionButton}
                                  >
                                    <Edit />
                                  </Button>
                                  <Button
                                    size="small"
                                    onClick={() =>
                                      this._deleteCriteria(index1, index2)
                                    }
                                    className={classes.actionButton}
                                  >
                                    <Delete />
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              ))
            )}
            <Dialog
              open={openCriteriaForm}
              onClose={this._handleToggleCriteriaForm}
              className={classes.dialogBranch}
            >
              <DialogTitle>Add Financial Criteria</DialogTitle>
              <DialogContent className={classes.formBranch}>
                <Grid container className={classes.gridRoot} spacing={2}>
                  <Grid
                    item
                    container
                    className={classes.gridRow}
                    xs={12}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        name="financial_criteria"
                        label="Financial Criteria"
                        value={stripHtmlTags(
                          supportChildren.financial_criteria
                        )}
                        onChange={this._onCriteriaFieldChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.gridRow}
                    xs={12}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        name="program_detail"
                        label="Program Detail"
                        value={stripHtmlTags(supportChildren.program_detail)}
                        onChange={this._onCriteriaFieldChange}
                        fullWidth
                        multiline
                      />
                    </Grid>
                    <Box fontStyle="italic">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Note: For fields that are not applicable please type,
                        N/A
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this._handleToggleCriteriaForm}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this._saveFinancialCriteria}
                  color="primary"
                  variant="contained"
                  disabled={!isFieldsComplete}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </ExpansionPanel>
    );
  }
}

const styles = (theme) => ({
  gridRoot: {
    width: 'auto',
    margin: 'auto',
    '& label': {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
      fontSize: 12,
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: 12,
    },
  },
  gridRow: {
    marginLeft: 0,
  },
  formBranch: {
    overflow: 'hidden',
  },
  patientTypeContainer: {
    border: '1px solid #dfdfdf',
    padding: 15,
    paddingBottom: 0,
    marginBottom: 20,
  },
  patientTypeRows: {
    paddingBottom: 25,
  },
  emptyText: {
    textAlign: 'center',
    padding: '90px 0',
    color: '#999999',
    width: '100%',
  },
  solidHeader: {
    backgroundColor: theme.palette.primary.main,
    '& th': {
      color: '#ffffff',
      padding: '10px 15px',
    },
  },
  actionButton: {
    minWidth: 20,
    margin: '0 auto',
  },
});

const mapDispatchToProps = ({ uiStore }) => {
  return {
    ...uiStore,
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProgramSupport));
