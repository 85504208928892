import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Markup } from 'interweave';

/**
 * Material UI - Core
 */
import {
  Grid,
  FormControl,
  FormLabel,
  Typography,
  Button,
  withStyles,
  Card,
  Divider,
  Chip,
  Avatar,
} from '@material-ui/core';

/**
 * Material UI - Icon
 */
import {
  FilterList as FilterListIcon,
  Event as EventIcon,
} from '@material-ui/icons';

/**
 * Custom UI Components
 */
import DatePicker from '../DatePicker/DatePicker.component';

/**
 * Styles
 */
const styles = (theme) => {
  return {
    sectionLabel: {
      padding: '24px 24px 0 24px',
    },
    tabRoot: {
      minWidth: 30,
    },
    formLabelRoot: {
      marginBottom: 8 * 2,
      textTransform: 'uppercase',
    },
  };
};

function TabContainer(props) {
  return (
    <Grid container style={{ padding: 8 * 3 }}>
      {props.children}
    </Grid>
  );
}

const dateFormat = 'YYYY-MM-DD';

class Filter extends Component {
  state = {
    dateFrom: moment().subtract(2, 'months').format(dateFormat),
    dateTo: moment().format(dateFormat),
  };

  componentDidMount() {
    const { start_date, end_date } = queryString.parse(
      this.props.history.location.search
    );
    start_date && this.onChangeDateFrom(start_date);
    end_date && this.onChangeDateTo(end_date);
  }

  onChangeDateFrom = (momentDate) =>
    this.setState({
      dateFrom: moment(momentDate).format(dateFormat),
    });
  onChangeDateTo = (momentDate) =>
    this.setState({
      dateTo: moment(momentDate).format(dateFormat),
    });

  // Submit Set Filters
  onSubmit = (e) => {
    e.preventDefault();
    this._applySetFilters();
  };

  // Clear/reset filters
  onClear = async (e, filter) => {
    e.preventDefault();
    const params = queryString.parse(this.props.history.location.search);

    if (filter === 'timeframe') {
      delete params.start_date;
      delete params.end_date;
      this.setState({
        dateFrom: moment().subtract(2, 'months').format(dateFormat),
        dateTo: moment().format(dateFormat),
      });
    }

    const stringParams = queryString.stringify(params);
    this.props.history.push(`${this.props.location.pathname}?${stringParams}`);
  };

  // For Applying Filters
  _applySetFilters = () => {
    const { dateFrom, dateTo } = this.state;
    const params = queryString.parse(this.props.history.location.search);
    delete params.start_date;
    delete params.end_date;

    const stringParams = queryString.stringify(params);
    this.props.history.push(
      `${this.props.location.pathname}?${stringParams}&start_date=${dateFrom}&end_date=${dateTo}`
    );
  };

  render() {
    const { classes, reportingStore: { filterNote, filterDateMaxRangeinMonths } } = this.props;
    const { dateFrom, dateTo } = this.state;
    const maxDate = moment(dateTo, dateFormat)
      .subtract(1, 'day')
      .format(dateFormat);
    const minDate = moment(dateFrom, dateFormat)
      .add(1, 'day')
      .format(dateFormat);
    const fromMinDate = filterDateMaxRangeinMonths ? moment(dateTo, dateFormat)
      .subtract(filterDateMaxRangeinMonths, 'months')
      .format(dateFormat) : null;
    const toMaxDate = filterDateMaxRangeinMonths ? moment(dateFrom, dateFormat)
      .add(filterDateMaxRangeinMonths, 'months')
      .format(dateFormat) : null;
    const { start_date, end_date } = queryString.parse(
      this.props.history.location.search
    );

    const ActiveFilters = () => (
      <Grid container style={{ padding: 8 * 3 }}>
        <FormControl>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            <Typography variant="body2" color="primary">
              Active Filters
            </Typography>
          </FormLabel>
          <Grid item xs={12}>
            {(start_date || end_date) && (
              <Chip
                avatar={
                  <Avatar>
                    <EventIcon />
                  </Avatar>
                }
                clickable={false}
                label={`${start_date || dateFrom} to ${end_date || dateTo}`}
                style={{ marginTop: 8, marginRight: 8 }}
                onDelete={(e) => this.onClear(e, 'timeframe')}
                color="primary"
              />
            )}
          </Grid>
        </FormControl>
      </Grid>
    );

    return (
      <Card>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <ActiveFilters />
            <Divider />
            <TabContainer>
              <FormControl style={{ width: '100%' }}>
                <FormLabel classes={{ root: classes.formLabelRoot }}>
                  <Typography variant="body2" color="primary">
                    Timeframe
                  </Typography>
                </FormLabel>
                <DatePicker
                  fullWidth={false}
                  label="From"
                  maxDate={maxDate}
                  {...(fromMinDate ? { minDate: fromMinDate, minDateMessage: `Date should not be before ${fromMinDate}` } : {})}
                  name="date"
                  onChange={this.onChangeDateFrom}
                  style={{ marginBottom: 16 }}
                  value={dateFrom}
                />

                <DatePicker
                  fullWidth={false}
                  label="To"
                  minDate={minDate}
                  {...(toMaxDate ? { maxDate: toMaxDate, maxDateMessage: `Date should not be after ${toMaxDate}` } : {})}
                  name="date"
                  onChange={this.onChangeDateTo}
                  value={dateTo}
                  style={{ marginBottom: 16 }}
                />
              </FormControl>
              {filterNote && (
                <Typography variant="caption" display="block" gutterBottom>
                  <Markup content={filterNote} />
                </Typography>
              )}
            </TabContainer>
            <Grid
              container
              spacing={1}
              style={{
                padding: 8 * 3,
                ...(filterNote ? { paddingTop: 0 } : {})
              }}
            >
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={this.onSubmit}
                  variant="contained"
                >
                  <FilterListIcon fontSize="small" style={{ marginRight: 6 }} />
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = (state) => state;
const mapDispatchToPropsRematch = ({ filterStore, uiStore }) => {
  return {
    ...filterStore,
    ...uiStore,
  };
};

export default withStyles(styles)(
  withRouter(connect(mapStateToPropsRematch, mapDispatchToPropsRematch)(Filter))
);
