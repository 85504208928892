import React from 'react';

import { connect } from 'react-redux';

import LoadingLogo from '../../LoadingLogo/LoadingLogo.component';

const LoginCallback = ({ signinRedirectCallback }) => {
  console.log(`LoginCallback`);
  signinRedirectCallback();

  return <LoadingLogo />;
};

const mapDispatchToProps = ({ authStore: { signinRedirectCallback } }) => {
  return {
    signinRedirectCallback,
  };
};

// const mapDispatchToProps = (state) => {
//   console.log(JSON.stringify(state.authStore))
//   return {
//     signinRedirectCallback,
//   };
// };

const ConnectedLoginCallback = connect(
  null,
  mapDispatchToProps,
  null
)(LoginCallback);

export default ConnectedLoginCallback;
