import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 40,
  },
}));

const Carousel = (props) => {
  const { images, imageDetails } = props;
  const [page, setPage] = useState(0);
  const classes = useStyles();

  function switchPage(direction) {
    let newPage = page + direction;
    if (newPage < 0) newPage = 0;
    if (newPage >= images.length) newPage = images.length - 1;
    setPage(newPage);
  }

  function onImageClick(imageURL) {
    const link = document.createElement('a');
    link.href = imageURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={classes.root}>
      <div className={classes.button}>
        {page > 0 && (
          <ArrowLeft
            style={{ fontSize: 40, cursor: 'pointer' }}
            onClick={() => switchPage(-1)}
          />
        )}
      </div>
      <div>
        {images[page] ? (
          <img
            style={{
              objectFit: 'contain',
              height: '240px',
              width: '480px',

              margin: '0 auto',
              display: 'block',
              cursor: 'pointer',
            }}
            src={images[page]}
            onClick={() => onImageClick(images[page])}
          ></img>
        ) : (
          ''
        )}
        {
          imageDetails[page] && imageDetails[page].trackingId ? (
            <div>Tracking ID: {imageDetails[page].trackingId}</div>
          ) : ('')
        }
        {
          imageDetails[page] && imageDetails[page].dateUploaded ? (
            <div>Date Uploaded: {imageDetails[page].dateUploaded}</div>
          ) : ('')
        }
      </div>
      <div className={classes.button}>
        {page < images.length - 1 && (
          <ArrowRight
            style={{ fontSize: 40, cursor: 'pointer' }}
            onClick={() => switchPage(1)}
          />
        )}
      </div>
    </div>
  );
};

export default Carousel;
