import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import { freeGoodTransactionManualUpdate } from '../../services/transaction.service';
import { TRANSACTIONS_CONSTANT } from '../../constants/common';
import { includes } from 'lodash';

class transactionBatchFreeGoodFLLDisapprove extends React.Component {
  _onClick = async () => {
    const { transaction_batch_id } = this.props.data;
    const { connect_program_id } = this.props.match.params;
    if (window.confirm('Do you want to disapprove this transaction batch?')) {
      await freeGoodTransactionManualUpdate({
        connect_program_id,
        transaction_batch_id,
        action: 'disapprove',
      })
        .then((res) => {
          alert(res.message);
          window.location.reload();
        })
        .catch((err) => alert(err.message));
    }
  };

  render() {
    const { transaction_status } = this.props.data;
    const validStatuses = [
      TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.FREE_GOODS_PENDING,
    ];

    return (includes(validStatuses, 'free_goods_'+transaction_status)) ? (
      <Button variant="contained" onClick={this._onClick} style={{ marginRight: 8 }}>Disapprove</Button>
    ) : null;
  }
}
export default withRouter(transactionBatchFreeGoodFLLDisapprove);
