import React from 'react';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import { freeGoodTransactionManualUpdate } from '../../services/transaction.service';
import { TRANSACTIONS_CONSTANT } from '../../constants/common';
import { includes } from 'lodash';

class transactionBatchFreeGoodFLLApprove extends React.Component {
  _onClick = async () => {
    const { transaction_batch_id } = this.props.data;
    const { connect_program_id } = this.props.match.params;
    if (window.confirm('Do you want to approve this transaction batch?')) {
      await freeGoodTransactionManualUpdate({
        connect_program_id,
        transaction_batch_id,
        action: 'approve',
      })
        .then((res) => {
          alert(res.message);
          window.location.reload();
        })
        .catch((err) => alert(err.message));
    }
  };

  render() {
    const { birth_date, transaction_status, free_goods_for } = this.props.data;
    const { connect_program_id } = this.props.match.params;

    const validStatuses = [
      TRANSACTIONS_CONSTANT.TRANSACTION_STATUS.FREE_GOODS_PENDING,
    ];

    if ((includes(validStatuses, 'free_goods_'+transaction_status))) {
      let buttonDisabled = false;
      if (!includes(connect_program_id, 'psplink-global')) {
        if (includes(free_goods_for, 'adult')) {
          buttonDisabled = false;
        } else {
          buttonDisabled = moment().diff(birth_date, 'months') < 12;
        }
      }
      
      return (
        <Button variant="contained" onClick={this._onClick} disabled={buttonDisabled} 
        style={{ marginRight: 8 }}>Approve</Button>
      )
    }

    return null;
  }
}
export default withRouter(transactionBatchFreeGoodFLLApprove);
