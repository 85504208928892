/* /src/components/auth/silentRenew.jsx */

import React from 'react';

import { connect } from 'react-redux';

import LoadingLogo from '../../LoadingLogo/LoadingLogo.component';
// import { rematchContext } from '../../../models/index.model';

const SilentRenew = ({ signinSilentCallback }) => {
  signinSilentCallback();

  return <LoadingLogo />;
};

const mapDispatchToProps = ({ authStore: { signinSilentCallback } }) => {
  return {
    signinSilentCallback,
  };
};

const ConnectedSilentRenew = connect(
  null,
  mapDispatchToProps,
  null
  // { context: rematchContext }
)(SilentRenew);

export default ConnectedSilentRenew;
