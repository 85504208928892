import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { putTransactionCodeFreeGoodApprove } from '../../services/transaction.service';

class transactionCodeFreeGoodApprove extends React.Component {
  _onClick = async () => {
    const { transaction_code_id } = this.props.data;
    const { connect_program_id } = this.props.match.params;
    if (window.confirm('Do you want to approve this free good?')) {
      await putTransactionCodeFreeGoodApprove({
        connect_program_id,
        transaction_code_id,
      })
        .then((res) => {
          alert(res.message);
          window.location.reload();
        })
        .catch((err) => alert(err.message));
    }
  };

  render() {
    return (
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={this._onClick}
        style={{ marginRight: 8 }}
      >
        Approve
      </Button>
    );
  }
}

export default withRouter(transactionCodeFreeGoodApprove);
