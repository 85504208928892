import stores from '../models/index.model';
import moment from 'moment-timezone';

const dateFormat = 'YYYY-MM-DD';

export default {
  state: {
    filters: {
      start_date: moment(window.programConfig?.start_date).format(dateFormat),
      end_date: moment().endOf('day').format(dateFormat),
      doctor: '',
    },
    hideFilterSidebar: false,
  },
  reducers: {
    setFilters(state, payload) {
      return { ...state, filters: payload };
    },
    setHideFilterSidebar(state, payload) {
      return { ...state, hideFilterSidebar: payload };
    },
  },
  effects: (dispatch) => ({
    async applyFilters(
      {
        start_date = moment(window.programConfig?.start_date)
          .startOf('day')
          .format(dateFormat),
        end_date = moment().endOf('day').format(dateFormat),
        doctor = '',
      },
      rootState
    ) {
      try {
        dispatch.filterStore.setFilters({
          start_date: start_date,
          end_date: end_date,
          doctor,
        });
      } catch (e) {
        console.error(e);
        stores.dispatch.uiStore.showPopupModal({
          title: 'Filters: Error',
          message: e.message,
          actions: [],
        });
      }
    },

    async hideFilterSidebar(payload) {
      try {
        dispatch.filterStore.setHideFilterSidebar(payload);
      } catch (e) {
        console.error(e);
      }
    },
  }),
};
