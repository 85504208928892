import React from 'react';

import { connect } from 'react-redux';

import LoadingLogo from '../../LoadingLogo/LoadingLogo.component';

const LogoutCallback = ({ signoutRedirectCallback }) => {
  signoutRedirectCallback();

  return <LoadingLogo />;
};

const mapDispatchToProps = ({ authStore: { signoutRedirectCallback } }) => {
  return {
    signoutRedirectCallback,
  };
};

const ConnectedLogoutCallback = connect(
  null,
  mapDispatchToProps,
  null
)(LogoutCallback);

export default ConnectedLogoutCallback;
