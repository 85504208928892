import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Grid, Button, withStyles } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import moment from 'moment-timezone';

import ProgramInformation from './ProgramInformation.component';
import ProgramBranchDetail from './ProgramBranchDetail.component';
import ProgramSupport from './ProgramSupport.component';

class ProgramDetail extends React.Component {
  state = {
    isEdit: false,
    programDetail: {
      brand_name: '',
      generic_name: '',
      drug_manufacturer: '',
      ngo: '',
      ngo_contact_details: '',
      inclusion_criteria: '',
      method_of_application: '',
      specialty: [],
      require_receipt: '',
      contact_information: [],
      support: [],
      version_number: '',
      published: 0 // 0 - false | 1 - true
    },
    isFieldsComplete: false,
  };

  componentDidMount() {
    const { data } = this.props;

    if (data) {
      const pd = JSON.stringify(data);
      this.setState({
        programDetail: JSON.parse(pd),
        isEdit: true,
      });
    }
  }

  __handlePublishToggle = async (toggleValue, isEdit) => {
    const currentProgramDetailsState = this.state.programDetail;

    await this.setState({
      programDetail: {
        ...currentProgramDetailsState,
        published: toggleValue
      }
    });

    if(isEdit){
      await this._saveProgramDetail()
    }
   
  }

  _saveProgramDetail = async () => {
    try {
      const { isEdit, programDetail } = this.state;
      const { breadcrumbs = [], history, match } = this.props;
      const { connect_program_id } = match.params;
     
      if (isEdit) {
        await this.props.putUpdateProgram(programDetail);
        this.props.showPopupModal({
          message: `The changes you've done with ${programDetail.brand_name} was succesfully saved.`,
          disableCloseButton: true,
          actions: [
            {
              label: 'OK, GOT IT!',
              onClick: () => window.location.reload(),
            },
          ],
        });
      } else {
        await this.props.postAddProgram(programDetail);
        this.props.showPopupModal({
          message: `You have succesfully added ${programDetail.brand_name}`,
          disableCloseButton: true,
          actions: [
            {
              label: 'OK, GOT IT!',
              onClick: () => {
                history.push(
                  `/${connect_program_id}/${breadcrumbs[0].page_key}`
                );
              },
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  _deleteProgramDetail = async () => {
    try {
      const { programDetail } = this.state;
      const { breadcrumbs = [], history, match } = this.props;
      const { connect_program_id } = match.params;

      this.props.showPopupModal({
        message: `Do you really want to delete ${programDetail.brand_name}?`,
        actions: [
          {
            label: 'Confirm',
            onClick: async () => {
              await this.props.deleteProgram(programDetail);
              history.push(`/${connect_program_id}/${breadcrumbs[0].page_key}`);
            },
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  _updateProgramDetailState = (programDetail) => {
    this.setState({ programDetail });
    this._checkFieldsCompletion(programDetail);
  };

  _cancelSaveProgram = () => {
    const { programDetail } = this.state;
    const { breadcrumbs = [], history, match } = this.props;
    const { connect_program_id } = match.params;

    this.props.showPopupModal({
      message: `Do you really want to cancel adding ${
        programDetail.brand_name ? programDetail.brand_name : 'a program'
      }? All changes will not be saved.`,
      actions: [
        {
          label: 'Confirm',
          onClick: () => {
            history.push(`/${connect_program_id}/${breadcrumbs[0].page_key}`);
          },
        },
      ],
    });
  };

  _checkFieldsCompletion = (programDetail) => {
    const { isEdit } = this.state;
    let { isFieldsComplete } = this.state;

    // ENABLE/DISABLE save button
    if (!isEdit) {
      for (const key in programDetail) {
        if (key === 'brand_name' && !programDetail[key]) {
          isFieldsComplete = false;
          break;
        }
        isFieldsComplete = true;
      }
      this.setState({ isFieldsComplete });
    }
  };

  render() {
    const { data, breadcrumbs = [], ngoList = [], match, classes } = this.props;
    const { isEdit, programDetail, isFieldsComplete } = this.state;
    const { connect_program_id } = match.params;

    return (
      <React.Fragment>
        <Grid
          container
          className={classes.gridRoot}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} container className={classes.mainHeader}>
            <Grid item xs={6}>
              <Typography variant="h5">
                {breadcrumbs.map((link, index) => (
                  <span key={link.page_key}>
                    <Link to={`/${connect_program_id}/${link.page_key}`}>
                      {link.label}
                    </Link>
                    <span className={classes.breadcrumbsSeparator}> </span>
                  </span>
                ))}
                {programDetail.brand_name ? programDetail.brand_name : '...'}
              </Typography>
              <Typography variant="h6" style={{ marginTop: 10 }}>
                Last Updated:{' '}
                {programDetail.updated_at
                  ? moment(programDetail.updated_at).format('DD MMM YYYY')
                  : moment().format('DD MMM YYYY')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="flex-start"
            >
                <ToggleButtonGroup
                  exclusive
                  onChange={() => this.__handlePublishToggle(programDetail.published === 1 ? 0 : 1, isEdit)}
                  disabled={!isFieldsComplete}
                >
                  <ToggleButton 
                    //className={classes.toggleButton}
                    selected={programDetail.published === 1 ? true : false} 
                    classes={{
                      root: classes.toggleButton,
                      selected: classes.selectedToggleButton
                    }}
                  >
                    Publish
                  </ToggleButton>

                  <ToggleButton 
                    selected={programDetail.published === 0 ? true : false} 
                    classes={{
                      root: classes.toggleButton,
                      selected: classes.selectedToggleButton
                    }}
                  >
                    Draft
                  </ToggleButton>
                </ToggleButtonGroup>
                  &nbsp;&nbsp;&nbsp;

              {isEdit ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this._deleteProgramDetail}
                >
                  Delete
                </Button>
              ) : (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this._saveProgramDetail}
                    disabled={!isFieldsComplete}
                  >
                    Save
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this._cancelSaveProgram}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <ProgramInformation
          programDetail={programDetail}
          ngoList={ngoList}
          saveProgramDetail={this._updateProgramDetailState}
          updateProgramDetail={this._saveProgramDetail}
          isEdit={isEdit}
        />

        <ProgramBranchDetail
          programDetail={data || programDetail}
          saveProgramDetail={this._updateProgramDetailState}
          updateProgramDetail={this._saveProgramDetail}
          isEdit={isEdit}
        />

        <ProgramSupport
          programDetail={data || programDetail}
          saveProgramDetail={this._updateProgramDetailState}
          updateProgramDetail={this._saveProgramDetail}
          isEdit={isEdit}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  mainHeader: {
    padding: '25px 0',
    marginTop: '25px',
    color: '#000000',
    '& a': {
      color: '#000000',
    },
  },
  breadcrumbsSeparator: {
    margin: '0 5px',
    fontWeight: 'bold',
  },
  gridRoot: {
    width: 'auto',
    margin: 'auto',
    '& label': {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
  },
  toggleButton: {
    padding: 7,
    height: 35,
  },
  selectedToggleButton: {
    backgroundColor: '#2e5ac0 !important',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#203f86 !important'
    }
  },
});

const mapDispatchToProps = ({ hkapiStore, uiStore }) => {
  return {
    ...hkapiStore,
    ...uiStore,
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProgramDetail));
