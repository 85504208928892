import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

class AddProgram extends React.Component {
  _onClick = async () => {
    const { match, dataLength } = this.props;

    const result = await this.props.getPageConfiguration({
      connect_program_id: match.params.connect_program_id,
      page_key: match.params.page_key,
    });

    const programLimit = result?.blocks[0]?.block_options?.program_limit;

    if (dataLength < programLimit) {
      this.props.history.push(
        '/' + this.props.match.params.connect_program_id + '/program-detail'
      );
    } else {
      this.props.showPopupModal({
        message: `Unable to add anymore programs. You have already reach the maximum upload limit of ${dataLength} programs.`,
        disableCloseButton: true,
        actions: [
          {
            label: 'OK, GOT IT!',
            onClick: () => {},
          },
        ],
      });
    }
  };

  render() {
    return (
      <Button variant="contained" color="primary" onClick={this._onClick}>
        + ADD PROGRAM
      </Button>
    );
  }
}

const mapDispatchToProps = ({ reportingStore, uiStore }) => {
  return {
    ...reportingStore,
    ...uiStore,
  };
};

export default connect(null, mapDispatchToProps)(AddProgram);
