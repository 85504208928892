import React from 'react';
import Button from '@material-ui/core/Button';
import { carryOverPatientTransaction } from '../../services/customer.service';

const carryOverTransaction = ({ data }) => {
  const { patient_id, program_id } = data;

  const onClick = async () => {
    if (window.confirm('Do you want to carry over the transactions under this patient?')) {
      await carryOverPatientTransaction({
        program_id,
        customer_id: patient_id,
      })
      .then((res) => {
        alert(res.message);
        window.location.reload();
      })
      .catch((err) => alert(err.message));
    }
  };

  return (
      <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Carry Over Transaction</Button>
  )
}

export default carryOverTransaction;