import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import numeral from 'numeral';

import { Link } from 'react-router-dom';

const AggregatedValue = ({
  value = 0,
  link = null,
  description = '',
  classes,
  tooltip = '',
  md = 4,
}) => (
  <Grid item xs={12} md={md}>
    {link ? (
      <Link to={link} style={{ textDecoration: 'none' }}>
        <Typography className={classes.value}>
          <b>{value}</b>
        </Typography>
        <Tooltip title={tooltip} placement="bottom-start">
          <Typography className={classes.description}>{description}</Typography>
        </Tooltip>
      </Link>
    ) : (
      <Fragment>
        <Typography className={classes.value}>
          {value}
        </Typography>
        <Tooltip title={tooltip} placement="bottom-start">
          <Typography className={classes.description}>{description}</Typography>
        </Tooltip>
      </Fragment>
    )}
  </Grid>
);

const styles = (theme) => ({
  value: {
    ...theme.typography.headline,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  description: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
});

export default withStyles(styles)(AggregatedValue);
