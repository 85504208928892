import React from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
class OptOutButton extends React.Component {
  /**
   * Handle click approve opt out event handler.
   * 
   * @param {Event} e 
   */
  handleClickApproveOptOut = async (e) => {
    try {
      const { onClick, data } = this.props;
  
      if (window.confirm('Please confirm patient status for opt-out.')) {
        await onClick({ user_id: data.user_id });
        alert('Patient has been opted out from the program.');
      }
    }
    catch (e) {
      console.error(e.message);
      alert(e.message);
    }
  }

  render() {
    return (
      <div>
        <Button
          style={{ padding: 8, margin: 4 }}
          color="red"
          variant="contained"
          size="small"
          onClick={this.handleClickApproveOptOut}
        >
          Approve Opt-Out
        </Button>
      </div>
    );
  }
}

OptOutButton.defaultProps = {
  data: null,
}

OptOutButton.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default OptOutButton;
