import React, { useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid, Link } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment'
import { partialUpdateCustomerInfo } from '../../services/customer.service';

const UpdateManualForm = ({ data }) => {
  const { patient_id, manual_form, program_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('Successfully updated!');
  const [isLoading, setLoading] = useState(false);
  const [manualFormImage, setManualFormImage] = useState(null);
  const [manualFormImageUrl, setManualFormImageUrl] = useState(null);
  const [manualFormType, setManualFormType] = useState(null);
  const [defaultManualForm, setDefaultManualForm] = useState(manual_form);
  let manualFormInputFileRef = useRef(null);

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();
  
  const handleClickAction = () => {
    setOpenModal(true);
  }

  const handleCloseSubmit = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setDefaultManualForm(manual_form);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }
 
  const onSubmit = async () => {
    try {
      setLoading(true);

      await partialUpdateCustomerInfo({
        program_id: program_id,
        customer_id: patient_id,
        payload: {
          metadata: {
            manual_form: manualFormImageUrl
          }
        }
      })

      handleCloseSubmit();
      setSuccessMessage('Manual form uploaded!');
      setConfirmation(true);
    } catch(err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  }


  const formContent = (
    <>
      <FormControl className={classes.formControl} component={'span'}>
        <FormLabel>
          <Typography component={'span'}>
            Manual Form
          </Typography>
        </FormLabel>
        {
          manualFormImageUrl ? 
          <>
            <Grid container>
              <Grid item sm={8}>
                {
                    manualFormType === 'application/pdf' ?
                    <Link href={manualFormImageUrl} target='_blank'>{manualFormImageUrl}</Link> : 
                    <>
                      <img width={"250px"} src={manualFormImageUrl} /> 
                      <Link href={manualFormImageUrl} target='_blank'>{manualFormImageUrl}</Link>
                    </>
                }
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { 
                  setManualFormImage(null);
                  setDefaultManualForm(null);
                  setManualFormImageUrl(null);
                }}>Remove File</Button>
              </Grid>
            </Grid>
          </> 
          :
          manual_form && defaultManualForm &&
            <Grid container>
              <Grid item sm={8}>
                {
                  defaultManualForm.split('.').pop() === 'pdf' ?
                  <Link href={defaultManualForm} target='_blank'>{defaultManualForm}</Link> : 
                  <>
                    <img width={"250px"} src={defaultManualForm} /> 
                    <Link href={defaultManualForm} target='_blank'>{defaultManualForm}</Link>
                  </>
                }
              </Grid>
              <Grid item sm={4}>
                <Button color="primary" variant="contained" onClick={()=> { 
                  setDefaultManualForm(null);
                }}>Remove File</Button>
              </Grid>
            </Grid>
        }
        <Button color="primary" variant="contained" onClick={()=> { manualFormInputFileRef.current.click() } }>Upload File</Button>
        <Input component={'span'} style={{ visibility: 'hidden' }} type="file" id="file" inputRef={manualFormInputFileRef} onChange={async (event) => {
          setLoading(true);
          const { result } = await upload(blobToFile(event.target.files[0]), program_id);
          if (event && event.target && event.target.files && event.target.files.length) {
            setManualFormImage(event.target.files[0]);
            setManualFormType(event.target.files[0].type);
          }
          if (result && result.url) {
            setManualFormImageUrl(result.url);
          }

          setLoading(false);
        }}></Input>
      </FormControl>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        { openConfirmation ? 
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
        : 
        <>
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
          <Button onClick={onSubmit} variant='contained' color='secondary'>
            Submit
          </Button> 
        </>
        }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='upload-manual-form'
      open={openModal} 
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      onSubmit={onSubmit}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Button variant="contained" color="primary" onClick={() => handleClickAction()} style={{ marginRight: 8 }}>
        Upload Manual Form
      </Button>
    </>
  )
};

export default UpdateManualForm;