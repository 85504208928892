//Components
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
// import { rematchContext } from '../../models/index.model';

//SCSS
import './PopupAlert.style.scss';

const popupRoot = document.getElementById('popup');
/**
 * [Component of the Popup Alert Modal.]
 * @props string            {title}     [req]  [Title of the alert that will be displayed on top of the modal.]
 * @props boolean           {open}      [req]  [Toggle Key of the alert modal.]
 * @props function          {onClose}   [req]  [Function to invoke on modal close.]
 * @props string            {message}   [req]  [content of the alert]
 * @props array(objects)    {actions}   [x]    [Array of actions to be placed in the modal]
 *      @attribute string       {label}     [req]  [Label to be shown on the button]
 *      @attribute function     {onClick}   [req]  [Function to call on action click]
 */
class PopupAlertComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.el = document.createElement('div');
  }

  componentDidMount() {
    popupRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    popupRoot.appendChild(this.el);
  }

  render() {
    const { uiStore, classes } = this.props;
    return ReactDOM.createPortal(
      <Dialog
        open={uiStore.popupAlert.open}
        disableEscapeKeyDown
        style={{
          ...(uiStore.popupAlert.nonTransparentOverlay
            ? { background: '#7c7c7c' }
            : {}),
        }}
      >
        <div>
          {uiStore.popupAlert.title && (
            <DialogTitle className={classes.titleSection}>
              {uiStore.popupAlert.title}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText>{uiStore.popupAlert.message}</DialogContentText>
          </DialogContent>
          {uiStore.popupAlert.actions ? (
            <DialogActions className={classes.actionsSection}>
              {!uiStore.popupAlert.disableCloseButton && (
                <Button
                  color="primary"
                  variant="contained"
                  key={`popup-action-button-cancel`}
                  onClick={this.props.closePopupModal}
                >
                  Close
                </Button>
              )}
              {uiStore.popupAlert.actions.map((action, actionIndex) => (
                <Button
                  color="secondary"
                  {...(action.isNotContained ? {} : {variant: 'contained'})}
                  key={`popup-action-button-${actionIndex}`}
                  onClick={() => {
                    action.onClick();
                    this.props.closePopupModal();
                  }}
                >
                  {action.label}
                </Button>
              ))}
            </DialogActions>
          ) : null}
        </div>
      </Dialog>,
      this.el
    );
  }
}

const styles = (theme) => ({
  titleSection: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  actionsSection: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#ffffff',
  },
});

// REMATCH
const mapStateToPropsRematch = (state) => state;
const mapDispatchToPropsRematch = ({ uiStore }) => {
  return {
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null
  // { context: rematchContext }
)(withStyles(styles)(PopupAlertComponent));
