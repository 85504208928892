import React, { useState } from 'react';
import { Button, makeStyles, TextField, FormControl, FormLabel, Typography } from '@material-ui/core';
import DatePicker from '../DatePicker/DatePicker.component';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { freeGoodTransactionManualUpdate } from '../../services/transaction.service';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  formControl: {
    width: '100%'
  }
}));

const currentDate = new Date();
const ACTION_APPROVE = 'approve';
const ACTION_UPDATE = 'update';
const ACTION_DISAPPROVE = 'disapprove';

const FreeGoodManualAction = ({ data }) => {
  const { transaction_batch_id, patient_id, quantity, program_id } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [manualActionData, setManualActionlData] = useState({
    collection_date: currentDate,
    remarks: null,
    quantity,
  });
  const [action, setAction] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('FOC Collection status successfully updated!');
  const [isLoading, setLoading] = useState(false);
  const handleClickAction = (action) => {
    setOpenModal(true);
    setAction(action);
    setModalTitle('Free Goods Quantity Update');
    if (action === ACTION_DISAPPROVE) {
      setModalTitle('Free Goods Status (Voided)');
    }

    if (action === ACTION_APPROVE) {
      setModalTitle('Free Goods Status (Collected)');
    }
  }
  const handleClose = () => {
    setOpenModal(false);
    setManualActionlData({
      collection_date: currentDate,
      remarks: null,
      quantity,
    });
    setAction(null);
    setConfirmation(false);
    setErrorMessage(null);
    if (action === null) {
      window.location.reload();
    }
  }
 
  const classes = useStyles();
  const onSubmit = async () => {
    try {
      if (!manualActionData.remarks) {
        setErrorMessage('Remarks is required');

        return;
      }

      if (!manualActionData.collection_date && action === ACTION_APPROVE) {
        setErrorMessage('Collection Date is required');

        return;
      }

      if (action === ACTION_UPDATE) {
        if (!manualActionData.quantity) {
          setErrorMessage('Quantity is required');

          return;
        }

        if (manualActionData.quantity === quantity) {
          setErrorMessage('Quantity should not be the same as current');

          return;
        }

        if (manualActionData.quantity < 1) {
          setErrorMessage('Quantity should be greater than 0');

          return;
        }
      }

      setLoading(true);
      let details = {};
      if (manualActionData.collection_date && action === ACTION_APPROVE) {
        details.collection_date = manualActionData.collection_date;
      }
      
      details.remarks = manualActionData.remarks;

      const payload = {
        manual_action_details: details,
      };

      if (manualActionData.quantity) {
        payload.transaction_details = {
          quantity: manualActionData.quantity
        }
      }

      await freeGoodTransactionManualUpdate(
        {
          connect_program_id: program_id,
          transaction_batch_id,
          action,
          payload,
        }
      )

      handleClose();

      if (action === ACTION_UPDATE) {
        setSuccessMessage('Free Goods quantity updated!');
      }
      setConfirmation(true);
    } catch(err) {
      setErrorMessage('Unable to update free good transaction');
    } finally {
      setLoading(false);
    }
  }

  const onCollectionDateChange = (date) => {
    setManualActionlData(prevState => ({
      ...prevState,
      collection_date: date
    }));
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setManualActionlData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Transaction Batch ID
          </Typography>
          <Typography>
          { transaction_batch_id }
          </Typography>
        </FormLabel>
      </FormControl>

      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Patient ID
          </Typography>
          <Typography>
          { patient_id }
          </Typography>
        </FormLabel>
      </FormControl>

      {action === ACTION_UPDATE &&
        <FormControl className={classes.formControl}>
        <FormLabel >
          <Typography>
            Quantity
          </Typography>
        </FormLabel>
        <TextField className={classes.field} 
          required={true} 
          name='quantity'
          type='number' 
          defaultValue={quantity}  
          onChange={onFieldInput}
        />
      </FormControl>
      }

      {action === ACTION_APPROVE &&
        <FormControl className={classes.formControl}>
        <FormLabel>
          <Typography>
            Collection Date
          </Typography>
        </FormLabel>
        <DatePicker 
          required={true}
          onChange={onCollectionDateChange}
          name='collection_date'
          className={classes.field} 
          value={manualActionData.collection_date}
          maxDate={currentDate}
        />
      </FormControl>
      }
      <FormControl className={classes.formControl}>
        <FormLabel>
          <Typography>
            Remarks
          </Typography>
        </FormLabel>
        <TextField 
          required={true}
          id='outlined-multiline-static'
          name='remarks'
          multiline
          rows={4}
          variant='outlined'
          className={classes.field}
          onChange={onFieldInput}
        />
      </FormControl>
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
      <Button onClick={handleClose} variant='contained'>
        Close
      </Button>
      {action !== null &&
        <Button onClick={onSubmit} variant='contained' color='secondary'>
        Submit
        </Button>
      }
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='free-good-details'
      open={openModal}
      title = {modalTitle}
      content = {formContent}
      children = {formButtons}
      >
     </Dialog>
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      >
     </Dialog>
     <Button variant='contained' color='secondary' onClick={() => handleClickAction(ACTION_UPDATE)}>
        <CreateIcon></CreateIcon>
      </Button>&nbsp;
      <Button variant='contained' color='primary' onClick={() => handleClickAction(ACTION_APPROVE)}>
        Collected
      </Button>&nbsp;
      <Button variant='contained' onClick={() => handleClickAction(ACTION_DISAPPROVE)}>
        Voided
      </Button>
      
    </>
  )
};

export default FreeGoodManualAction;