import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import './LoadingLogo.style.scss';

class LoadingLogo extends React.Component {
  render() {
    return (
      <div className="logo-loading-container">
        {/*<div
          className="loader"
          style={{ border: `8px solid ${palette.primary.main}` }}
        />*/}
        <div className="logo-loader">
          <img
            src={window.programConfig?.program_metadata?.page_theme?.logo}
            alt="logo"
            style={{ width: '100%' }}
          />

          <div>Loading...</div>
        </div>
      </div>
    );
  }
}

export default withTheme(LoadingLogo);
