import React from 'react';
import Button from '@material-ui/core/Button';
import { voidTransactionBatch } from '../../services/transaction.service';

const VoidTransaction = ({ data }) => {
    const { transaction_batch_id, transaction_status, program_id } = data;

    const onClick = async () => {
        if (window.confirm('Do you want to void this transaction?')) {
            await voidTransactionBatch({
                program_id,
                transaction_batch_id,
            })
            .then((res) => {
                alert(res.message);
                window.location.reload();
            })
            .catch((err) => alert(err.message));
        }
    };

    return (
        transaction_status !== 'voided' && 
        <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Void Transaction</Button>
    )
}

export default VoidTransaction;