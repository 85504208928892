import React from 'react';

import {
  Card as MaterialCard,
  CardContent,
  Typography,
  CircularProgress,
  withStyles,
  Grid,
  Button,
} from '@material-ui/core';

export const Card = ({ children, cardClassName, cardContentClassName }) => (
  <MaterialCard className={cardClassName}>
    <CardContent className={cardContentClassName}>{children}</CardContent>
  </MaterialCard>
);

const dataCardStyles = (theme) => ({
  progress: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.875rem',
  },
  subtitle: {
    fontSize: '0.75rem',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
    paddingBottom: 7,
    marginBottom: 20,
  },
});

export const DataCard = withStyles(dataCardStyles)(
  ({
    icon,
    title,
    subtitle,
    children,
    isLoading = false,
    progressSize = 100,
    classes,
    cardClassName,
    cardContentClassName,
    actionButton,
  }) => (
    <Card
      cardClassName={cardClassName}
      cardContentClassName={cardContentClassName}
    >
      {isLoading ? (
        <div className={classes.progress}>
          <CircularProgress size={progressSize} thickness={2} />
        </div>
      ) : actionButton ? (
        <div>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Grid container alignItems="center" justify="flex-start">
                {icon}
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="flex-start"
              className={classes.subtitle}
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={actionButton.action}
              >
                {actionButton.iconComponent}
                {actionButton.text}
              </Button>
            </Grid>
          </Grid>
          {children}
        </div>
      ) : (
        <div>
          {(icon || title || subtitle) && (
            <React.Fragment>
              <Grid container alignItems="center" justify="flex-start">
                {icon}
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </React.Fragment>
          )}
          {children}
        </div>
      )}
    </Card>
  )
);

export const SummaryCard = (props) => (
  <DataCard {...props}>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {props.children}
    </Grid>
  </DataCard>
);

export const GraphCard = (props) => (
  <DataCard {...props}>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}
      style={{ width: 750, height: 270 }}
    >
      {props.children}
    </Grid>
  </DataCard>
);
