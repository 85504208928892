import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookieStorage from 'cookie-storage-v2';

// import { rematchContext } from '../../models/index.model';

// Components
import BlockDetail from '../../components/BlockDetail/BlockDetail.component';
import { Button } from '@material-ui/core';

// type MyProps = { filterStore: any; summaryStore: any; getSummary: any };
// type MyState = { value: string };

const FORBIDDEN_REPORT_LABEL_UPERIO = [
  'summary (oncology)',
  'outlet activity (oncology)',
  'transaction view (oncology)',
  'patient overview (oncology)',
  'patient activity (oncology)',
  'pharmacy raw data (oncology)',
  'pharmacy overview (oncology)',
  'distributor (aam) report (oncology)',
];

const FORBIDDEN_REPORT_LABEL_ONCOLOGY = [
  'summary',
  'outlet activity',
  'transaction view',
  'patient overview',
  'patient activity',
  'pharmacy raw data',
  'pharmacy overview',
  'distributor (aam) report',
]
class PageContainer extends React.Component /*<MyProps, MyState>*/ {
  constructor(props) {
    super(props);

    this.state = {
      pageConfig: {
        blocks: [],
      },
    };
  }

  async componentDidMount() {
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      await this._loadData();
    }
  }

  /**
   * Filter block options based on user type for Stellar report.
   *
   * @param {Enum} userType nvid-oncology-user, nvid-uperio-user.
   * @param {Object} pageConfig Page config object.
   * @returns {Object}
   */
  filterAllowedButtons = (userType, pageConfig) => {
    let block = pageConfig.blocks[0];

    switch (userType) {
      case 'nvid-oncology-user':
        block = {
          ...block,
          block_options: {
            ...block.block_options,
            reports: block.block_options.reports.filter((item) => !FORBIDDEN_REPORT_LABEL_ONCOLOGY.includes(item.label.toLowerCase()))
          },
        };

        return block;
      case 'nvid-uperio-user':
        block = {
          ...block,
          block_options: {
            ...block.block_options,
            reports: block.block_options.reports.filter((item) => !FORBIDDEN_REPORT_LABEL_UPERIO.includes(item.label.toLowerCase()))
          },
        };

        return block;

      default:
        return pageConfig.blocks[0];
    }
  }

  _loadData = async () => {
    let block = [];
    this.setState({ pageConfig: {} });
    const programId = this.props.match.params.connect_program_id;
    const pageConfig = (await this.props.getPageConfiguration({
      connect_program_id: programId,
      page_key: this.props.match.params.page_key,
    })) || { blocks: [] };
    this.setState({ pageConfig });
    // TODO: Un-atomic, make it atomic, store it in auth reducer together along with localStorage
    // Quick Fix
    if (programId.includes('nvid')) {
      const userType = cookieStorage.getItem(`user-type`);

      if (pageConfig.blocks.length === 0) {
        return;
      }

      block = this.filterAllowedButtons(userType, pageConfig);
      this.setState({
        pageConfig: {
          ...pageConfig,
          blocks: [block]
        }
      });
    }
  };

  render() {
    return (
      <div spacing={1}>
        {this.state.pageConfig.blocks &&
          this.state.pageConfig.blocks.map((blockConfig, idx) => {
            return (<BlockDetail
              key={blockConfig.id}
              blockConfig={blockConfig}
              {...this.props}
            />)
        })}
      </div>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = (state) => state;
const mapDispatchToPropsRematch = ({ reportingStore }) => {
  return {
    ...reportingStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch
)(PageContainer);

PageContainer.propTypes = {
  history: PropTypes.object,
  blockConfig: PropTypes.object,
  getPageConfiguration: PropTypes.func,
};
