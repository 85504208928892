import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class BpDeliveryButton extends React.Component {
  /**
   * Handle click bp delivered event handler.
   * 
   * @param {Event} e 
   */
  _handleClickYes = async (e) => {
    try {
      const { onClickYes, data } = this.props;
  
      if (window.confirm('Please confirm whether BP Machine has already been delivered to patient.')) {
        await onClickYes({ user_id: data.user_id });
        alert('BP machine is delivered');
      }
    }
    catch (e) {
      alert(e.message);
    }
  }


  /**
   * Handle click bp NOT delivered event handler.
   * 
   * @param {Event} e 
   */
  _handleClickNo = async (e) => {
    try {
      const { onClickNo, data } = this.props;
  
      if (window.confirm('Please confirm whether BP Machine has already been delivered to patient.')) {
        await onClickNo({ user_id: data.user_id });
        alert('BP machine is not delivered yet');
      }
    }
    catch (e) {
      alert(e.message);
    }
  }

  render() {
    return (
      <div>
        <Button
          style={{ padding: 8, margin: 4 }}
          color="red"
          variant="contained"
          size="small"
          onClick={this._handleClickNo}
        >
          No
        </Button>
        <Button
          style={{ padding: 8, margin: 4 }}
          color="primary"
          variant="contained"
          size="small"
          onClick={this._handleClickYes}
        >
          Yes
        </Button>
      </div>
    );
  }
}

BpDeliveryButton.propTypes = {
  data: PropTypes.object,
  onClickYes: PropTypes.func,
  onClickNo: PropTypes.func,
}

export default BpDeliveryButton;
