import React from 'react';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { Markup } from 'interweave';

function stripHtmlTags(value) {
  return value?.replace(/<br\s*\/?>/gm, '\n').replace(/<[^>]*>?/gm, '');
}

class ProgramInformation extends React.Component {
  state = {
    information: {
      brand_name: '',
      generic_name: '',
      drug_manufacturer: '',
      ngo: '',
      ngo_contact_details: '',
      inclusion_criteria: '',
      method_of_application: '',
      specialty: [],
      require_receipt: '',
      version_number: '',
    },
    enableEdit: false,
    originalState: null,
  };

  async componentDidMount() {
    const { getSpecialtyList } = this.props;

    await getSpecialtyList();
    this.setState({
      information: {
        brand_name: this.props.programDetail.brand_name,
        generic_name: this.props.programDetail.generic_name,
        drug_manufacturer: this.props.programDetail.drug_manufacturer,
        ngo: this.props.programDetail.ngo,
        ngo_contact_details: this.props.programDetail.ngo_contact_details,
        inclusion_criteria: this.props.programDetail.inclusion_criteria,
        method_of_application: this.props.programDetail.method_of_application,
        specialty: this.props.programDetail.specialty,
        require_receipt: this.props.programDetail.require_receipt,
        version_number: this.props.programDetail.version_number,
      },
    });
  }

  _onInfoFieldChange = (e) => {
    const { name, className } = e.target;
    let { value } = e.target;
    const { programDetail } = this.props;
    const { information } = this.state;

    // if (name === 'ngo_contact_details') {
    //   value = value.replace(/[a-zA-Z]/g, '');
    // }

    if (className.includes('inputMultiline')) {
      value = value.replace(/(\r\n|\n|\r)/gm, '<br />');
    }

    information[name] = value;
    programDetail[name] = value;
    this.setState({ information });
    this.props.saveProgramDetail(programDetail);
  };

  _onSpecialtyChange = (e) => {
    let { value } = e.target;
    const { programDetail } = this.props;
    const { information } = this.state;

    information.specialty = value;
    programDetail.specialty = value;
    this.setState({ information });
    this.props.saveProgramDetail(programDetail);
  };

  _onNgoChange = (e) => {
    let { value } = e.target;
    const { programDetail } = this.props;
    const { information } = this.state;

    information.ngo = value;
    programDetail.ngo = value;
    this.setState({ information });
    this.props.saveProgramDetail(programDetail);
  };

  _updateProgramDetail = async () => {
    await this.props.updateProgramDetail();
    this.setState({ enableEdit: false });
  };

  _cancelUpdate = () => {
    const { programDetail } = this.props;
    const originalState = JSON.parse(this.state.originalState);

    this.props.showPopupModal({
      message: `Any changes you've done with ${programDetail.brand_name} will not be saved. Do you still want to cancel your edits?`,
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            this.setState({
              information: originalState,
              enableEdit: false,
            });
            this.props.saveProgramDetail({
              ...programDetail,
              originalState,
            });
          },
        },
      ],
    });
  };

  render() {
    const {
      ngoList,
      isEdit,
      classes,
      hkapiStore: { specialtyList },
    } = this.props;
    const { information, enableEdit } = this.state;
    const disableField = isEdit ? (enableEdit ? false : true) : false;

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Typography variant="h6">Program Information</Typography>
            </Grid>
            {isEdit && (
              <Grid
                item
                xs={6}
                container
                justify="flex-end"
                alignItems="flex-start"
              >
                {enableEdit ? (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this._updateProgramDetail}
                    >
                      Save
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this._cancelUpdate}
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        originalState: JSON.stringify(information),
                        enableEdit: true,
                      })
                    }
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <Grid container className={classes.gridRoot} spacing={8}>
          <Grid item container className={classes.gridRow} xs={12} spacing={3}>
            <Grid item xs={3}>
              <TextField
                name="brand_name"
                label="Brand Name"
                value={information.brand_name}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="generic_name"
                label="Generic Name"
                value={information.generic_name}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="drug_manufacturer"
                label="Drug Manufacturer"
                value={information.drug_manufacturer}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                className={classes.formControl}
                disabled={disableField}
              >
                <InputLabel>Non-Government Organization (NGO)</InputLabel>
                <Select
                  name="ngo"
                  value={information.ngo}
                  onChange={this._onNgoChange}
                  className={classes.selectWrap}
                  input={<Input />}
                  disabled={disableField}
                >
                  {ngoList.map((ngo) => (
                    <MenuItem className={classes.menuItem} key={ngo} value={ngo}>
                      <Markup content={ngo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container className={classes.gridRow} xs={12} spacing={3}>
            <Grid item xs={3}>
              <TextField
                name="ngo_contact_details"
                label="NGO Contact"
                value={stripHtmlTags(information.ngo_contact_details)}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                // onKeyPress={(e) => {
                //   let charCode = e.which;
                //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                //     e.preventDefault();
                //   }
                // }}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="inclusion_criteria"
                label="Inclusion Criteria (服務對象)"
                value={stripHtmlTags(information.inclusion_criteria)}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="method_of_application"
                label="Method of Application"
                value={stripHtmlTags(information.method_of_application)}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="require_receipt"
                label="Require Receipt"
                value={stripHtmlTags(information.require_receipt)}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.gridRow} xs={12} spacing={3}>
            <Grid item xs={3}>
              <FormControl
                className={classes.formControl}
                disabled={disableField}
              >
                <InputLabel>Specialty</InputLabel>
                <Select
                  multiple
                  name="specialty"
                  value={information.specialty}
                  onChange={this._onSpecialtyChange}
                  input={<Input />}
                  disabled={disableField}
                  MenuProps={{autoFocus: false}}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {specialtyList.map((specialty) => (
                    <MenuItem key={specialty.value} value={specialty.value}>
                      <Checkbox checked={information.specialty.indexOf(specialty.value) > -1} />
                      <ListItemText primary={specialty.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="version_number"
                label="Version Number"
                value={information.version_number}
                onChange={this._onInfoFieldChange}
                disabled={disableField}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </ExpansionPanel>
    );
  }
}

const styles = (theme) => ({
  gridRoot: {
    width: 'auto',
    margin: 'auto',
    '& label': {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
      fontSize: 12,
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: 12,
    },
  },
  gridRow: {
    marginLeft: 0,
  },
  formControl: {
    display: 'flex',
  },
  selectWrap: {
    '& .MuiSelect-select': {
      whiteSpace: 'normal',
    },
    '& p': {
      margin: 0,
    },
  },
  menuItem: {
    '& p': {
      margin: 0,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: '#e6e9f0',
  },
});

const mapStateToProps = (state) => ({
  hkapiStore: state.hkapiStore,
});

const mapDispatchToProps = ({ hkapiStore, uiStore }) => {
  return {
    ...hkapiStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProgramInformation));
