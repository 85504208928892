import cookieStorage from 'cookie-storage-v2';
export default class CookieStorage {

  clear() {
    cookieStorage.clear();
  }
  removeItem(key) {
    return cookieStorage.removeItem(key);
  }

  getItem(key) {
    return cookieStorage.getItem(key) ?
      JSON.stringify(cookieStorage.getItem(key))
      : null;
  }

  setItem(key, value) {
    if(key.includes("oidc.user")) {
      value = JSON.parse(value);
      delete value.id_token;
      value = JSON.stringify(value);
    }
    cookieStorage.setItem(key, value, {
      secure: true,
      expires: {
        months: 1
      }
    });
  }
}
