import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
} from '@material-ui/core';

class Logout extends React.Component {
  handleLogout = async () => {
    this.props.showPopupModal({
      title: window.programConfig.program_name,
      message: `Are you sure you want to logout?`,
      disableCloseButton: true,
      actions: [
        {
          label: 'Yes',
          onClick: async () => {
            await this.props.logout();
          },
        },
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    return (
      <Button color="primary" onClick={this.handleLogout}>
        <Typography>Logout</Typography>
      </Button>
    );
  }
}

const mapDispatchToProps = ({ authStore, uiStore }) => {
  return {
    ...authStore,
    ...uiStore,
  };
};

export default connect(null, mapDispatchToProps)(Logout)