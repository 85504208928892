import React from 'react';
import Button from '@material-ui/core/Button';
import { sendHcpPrefilledLink } from '../../services/user.service';
import { includes } from 'lodash';

const sendPrefilledLink = ({ data }) => {
    const { email, hcp_name, hcp_id, user_type } = data;
    const nodeEnv = `${window.config.NODE_ENV}`;
    const programId = `${window.config.PROGRAM_ID}`;

    const onClick = async () => {
        if (window.confirm(`Are you sure you want to send an email with prefilled link?`)) {
            await sendHcpPrefilledLink({
                program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
                payload: {
                    email, hcp_name, hcp_id, user_type
                }
            })
            .then((res) => {
                alert(res.message);
                window.location.reload();
            })
            .catch((err) => alert(err.message));
        }
    };

    return (
        <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Send Prefilled Link to HCP</Button>
    )
}

export default sendPrefilledLink;
