import React from 'react';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  withStyles,
} from '@material-ui/core';
import { AddPhotoAlternateSharp } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import MUITable from '../MUITable/MUITable.component';

class ProgramBranchDetail extends React.Component {
  state = {
    isFieldsComplete: false,
    openBranchForm: false,
    branchList: [],
    contactInformation: {
      branch_name: '',
      address: '',
      email: '',
      contact_number: [],
      address_image_url: '',
    },
    editContactInfoIndex: null,
    enableEdit: false,
    originalState: null,
    fileUploading: false,
  };

  componentDidMount() {
    this.setState({ branchList: this.props.programDetail.contact_information });
  }

  _handleToggleBranchForm = () => {
    this.setState({
      openBranchForm: !this.state.openBranchForm,
      contactInformation: {
        branch_name: '',
        address: '',
        email: '',
        contact_number: [],
        address_image_url: '',
      },
      editContactInfoIndex: null,
    });
  };

  _onBranchFieldChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    const { contactInformation } = this.state;

    if (name === 'contact_number') {
      // value = value.replace(/[a-zA-Z]/g, '');
      contactInformation[name][0] = {
        label: '',
        value,
      };
    } else {
      contactInformation[name] = value;
    }

    this.setState({ contactInformation });
    this._checkFieldsCompletion();
  };

  _onImageDrop = async (file) => {
    const { contactInformation } = this.state;

    if (file[0].type.includes('image')) {
      console.log('original', file[0]);
      contactInformation['address_image_url'] = file[0];
    } else {
      contactInformation['address_image_url'] = '';
    }
    this.setState({ contactInformation });
    this._checkFieldsCompletion();
  };

  _onSaveBranchDetail = async () => {
    const { branchList } = this.state;
    const { programDetail } = this.props;
    const { contactInformation, editContactInfoIndex } = this.state;
    this.setState({ isFieldsComplete: false });

    // UPLOAD working already comment first
    if (contactInformation.address_image_url && typeof contactInformation.address_image_url !== 'string') {
      this.setState({ fileUploading: true });
      const options = {
        maxSizeMB: 1,
      };
      let compressedFile = await imageCompression(contactInformation.address_image_url, options);
      compressedFile = new File([compressedFile], compressedFile.name, {...compressedFile});
      console.log('compressed', compressedFile);
      const addressMapUrl = await this.props.postUploadMap({
        file: compressedFile,
      });
      // const addressMapUrl = 'https://via.placeholder.com/500x400';
      contactInformation.address_image_url = addressMapUrl;
      this.setState({ fileUploading: false });
    }

    if (editContactInfoIndex !== null) {
      branchList[
        editContactInfoIndex
      ] = contactInformation;
    } else {
      branchList.push(contactInformation);
    }
    programDetail.contact_information = branchList;
    this.setState({
      openBranchForm: false,
      branchList,
      contactInformation: {
        branch_name: '',
        address: '',
        email: '',
        contact_number: [],
        address_image_url: '',
      },
      editContactInfoIndex: null,
    });
    this.props.saveProgramDetail(programDetail);
  };

  _onBranchEdit = (index) => {
    const programDetail = JSON.stringify(this.state.branchList[index]);

    this.setState({
      editContactInfoIndex: index,
      contactInformation: JSON.parse(programDetail),
      openBranchForm: true,
    });
  };

  _onReplaceImage = () => {
    const { contactInformation } = this.state;

    contactInformation.address_image_url = '';
    this.setState({ contactInformation });
  };

  _onBranchDelete = (index) => {
    const { programDetail } = this.props;

    this.props.showPopupModal({
      message: 'Do you really want to delete Program Branch?',
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            programDetail.contact_information.splice(index, 1);
            this.props.saveProgramDetail(programDetail);
            this.setState({ branchList: programDetail.contact_information });
          },
        },
      ],
    });
  };

  _checkFieldsCompletion = () => {
    const { contactInformation } = this.state;
    let { isFieldsComplete } = this.state;

    // ENABLE/DISABLE save button
    for (const key in contactInformation) {
      if (key === 'contact_number') {
        if (contactInformation[key].length > 0) {
          if (contactInformation[key][0].value) {
            isFieldsComplete = true;
            break;
          }
        }
      } else {
        if (contactInformation[key]) {
          isFieldsComplete = true;
          break;
        }
      }
      isFieldsComplete = false;
    }
    this.setState({ isFieldsComplete });
  };

  _updateProgramDetail = async () => {
    await this.props.updateProgramDetail();
    this.setState({ enableEdit: false });
  };

  _cancelUpdate = () => {
    const { programDetail } = this.props;
    const originalState = JSON.parse(this.state.originalState);

    this.props.showPopupModal({
      message: `Any changes you've done with ${programDetail.brand_name} will not be saved. Do you still want to cancel your edits?`,
      disableCloseButton: true,
      actions: [
        {
          label: 'Cancel',
          isNotContained: true,
          onClick: () => {},
        },
        {
          label: 'Confirm',
          onClick: () => {
            this.setState({
              branchList: originalState,
              enableEdit: false,
            });
            this.props.saveProgramDetail({
              ...programDetail,
              contact_information: originalState,
            });
          },
        },
      ],
    });
  };

  render() {
    const { isEdit, classes } = this.props;
    const {
      branchList,
      openBranchForm,
      contactInformation,
      isFieldsComplete,
      enableEdit,
      fileUploading,
    } = this.state;
    const disableActions = isEdit ? (enableEdit ? false : true) : false;

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Typography variant="h6">
                Program Branch Details
                {isEdit && enableEdit && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this._handleToggleBranchForm}
                    style={{ marginLeft: 20 }}
                  >
                    + Add Branch Details
                  </Button>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="flex-start"
            >
              {isEdit ? (
                enableEdit ? (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this._updateProgramDetail}
                    >
                      Save
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this._cancelUpdate}
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        originalState: JSON.stringify(branchList),
                        enableEdit: true,
                      })
                    }
                  >
                    Edit
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this._handleToggleBranchForm}
                >
                  + Add Branch Details
                </Button>
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <Divider />
        <Grid container className={classes.gridRoot} spacing={8}>
          <Grid item xs={12}>
            <MUITable
              {...this.props}
              columns={[
                { Header: 'Branch Name', accessor: 'branch_name', width: '18%' },
                { Header: 'Branch Address', accessor: 'address', width: '25%' },
                { Header: 'Branch Email', accessor: 'email', width: '16%' },
                {
                  Header: 'Branch Contact',
                  accessor: 'contact_number',
                  col_type: 'hkapi-contact',
                  width: '16%',
                },
                {
                  Header: 'Branch Map',
                  accessor: 'address_image_url',
                  col_type: 'image',
                  width: '15%',
                },
                ...(disableActions
                  ? [{}]
                  : [{ Header: '', col_type: 'edit-delete', width: '10%' }]),
              ]}
              data={branchList}
              onEdit={this._onBranchEdit}
              onDelete={this._onBranchDelete}
              emptyText="Please add program branch to complete program branch details"
              solidHeader={true}
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10, 15]}
            />
            <Dialog
              open={openBranchForm}
              onClose={this._handleToggleBranchForm}
              className={classes.dialogBranch}
            >
              <DialogTitle>Add Program Branch Detail</DialogTitle>
              <DialogContent className={classes.formBranch}>
                <Grid container className={classes.gridRoot} spacing={2}>
                  <Grid
                    item
                    container
                    className={classes.gridRow}
                    xs={12}
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <TextField
                        name="branch_name"
                        label="Branch Name"
                        value={contactInformation.branch_name}
                        onChange={this._onBranchFieldChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="address"
                        label="Branch Address"
                        value={contactInformation.address}
                        onChange={this._onBranchFieldChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="email"
                        label="Branch Email"
                        value={contactInformation.email}
                        onChange={this._onBranchFieldChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="contact_number"
                        label="Branch Contact"
                        value={contactInformation.contact_number[0]?.value}
                        onChange={this._onBranchFieldChange}
                        // onKeyPress={(e) => {
                        //   let charCode = e.which;
                        //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        //     e.preventDefault();
                        //   }
                        // }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className={classes.gridRow}
                    xs={12}
                    spacing={4}
                  >
                    <div className={classes.dropzoneContainer}>
                      <Grid container>
                        <Grid item xs={6}>
                          <InputLabel>Branch Map Image</InputLabel>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          container
                          justify="flex-end"
                          alignItems="flex-start"
                        >
                          {contactInformation.address_image_url && typeof contactInformation.address_image_url ==
                            'string' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this._onReplaceImage}
                            >
                              Replace Image
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                      {contactInformation.address_image_url && typeof contactInformation.address_image_url ==
                      'string' ? (
                        <img
                          className={classes.mapPreview}
                          src={contactInformation.address_image_url}
                        />
                      ) : (
                        <Dropzone accepted="image/*" onDrop={this._onImageDrop}>
                          {({ getRootProps, getInputProps }) => {
                            let url;
                            const photo = contactInformation.address_image_url;
                            if (photo && typeof photo?.name == 'string') {
                              url = URL.createObjectURL(photo);
                            }
                            return (
                              <section className={classes.dropzoneSection}>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {photo ? (
                                    <img
                                      className={classes.mapPreview}
                                      src={url}
                                    />
                                  ) : (
                                    <div className={classes.uploadBox}>
                                      <div>
                                        <AddPhotoAlternateSharp fontSize="large" />
                                      </div>
                                      Upload Map Address Image
                                    </div>
                                  )}
                                </div>
                              </section>
                            );
                          }}
                        </Dropzone>
                      )}
                      <Box fontStyle="italic">
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Note: For fields that are not applicable please type,
                          N/A
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this._handleToggleBranchForm} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this._onSaveBranchDetail}
                  variant="contained"
                  color="primary"
                  disabled={!isFieldsComplete}
                >
                  {fileUploading ? 'Uploading...' : 'Save'}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </ExpansionPanel>
    );
  }
}

const styles = (theme) => ({
  gridRoot: {
    width: 'auto',
    margin: 'auto',
    '& label': {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
      fontSize: 12,
    },
    '& label.MuiInputLabel-shrink': {
      fontSize: 12,
    },
  },
  gridRow: {
    marginLeft: 0,
  },
  formBranch: {
    overflow: 'hidden',
  },
  dropzoneContainer: {
    width: '100%',
    padding: '36px 8px',
    paddingBottom: 0,
    '& label': {
      marginBottom: '25px',
    },
  },
  dropzoneSection: {
    margin: 'auto',
    width: '100%',
  },
  mapPreview: {
    maxHeight: 500,
    maxWidth: 500,
    margin: 'auto',
    display: 'block',
  },
  uploadBox: {
    width: '100%',
    padding: '50px',
    backgroundColor: '#f7f7f7',
    border: '1px solid #cccccc',
    textAlign: 'center',
    marginBottom: 15,
  },
});

const mapDispatchToProps = ({ hkapiStore, uiStore }) => {
  return {
    ...hkapiStore,
    ...uiStore,
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProgramBranchDetail));
