import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const platform = (theme) =>
  createMuiTheme({
    palette: {
      primary: {
        main: theme.primary_color || '#d82819',
      },
      secondary: {
        main: theme.secondary_color || '#d82819',
      },
      error: {
        main: '#E53935',
      },
      text: {
        primary: '#555',
      },
      success: {
        main: '#05a41f'
      }
    },
    typography: {
      fontSize: 13,
      // lineHeight: 1.5,
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  });

class Header extends React.Component {
  render() {
    if (!window.programConfig) return null;

    const { program_name = 'Connect', program_metadata = {} } = window.programConfig;

    return (
      <Helmet defer={false}>
        <link
          rel="shortcut icon"
          href={program_metadata?.page_theme?.favicon || '/psplink_favicon.png'}
        />
        <meta
          name="theme-color"
          content={program_metadata?.page_theme?.primary_color || '#d82819'}
        />
        <title>{`${program_name && program_name} Dashboard`}</title>
      </Helmet>
    );
  }
}

export { platform, Header };
export default Header;
