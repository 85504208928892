import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class BlacklistButton extends React.Component {
  /**
   * Handle click black list event handler.
   * 
   * @param {Event} e 
   */
  handleClickBlacklist = async (e) => {
    try {
      const { onClick, data } = this.props;
  
      if (window.confirm('Please confirm patient status for blacklist.')) {
        await onClick({ user_id: data.user_id });
        alert('Patient has been black listed from the program.');
      }
    }
    catch (e) {
      console.error(e.message);
      alert(e.message);
    }
  }

  render() {
    return (
      <div>
        <Button
          style={{ padding: 8, margin: 4 }}
          color="red"
          variant="contained"
          size="small"
          onClick={this.handleClickBlacklist}
        >
          Blacklist
        </Button>
      </div>
    );
  }
}

BlacklistButton.defaultProps = {
  data: null,
}

BlacklistButton.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default BlacklistButton;
